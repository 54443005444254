.page-template
  display: flex
  flex-direction: row
  +max-screen($breakpoint-md)
    flex-direction: column

  &__col-right
    margin-left: 30px
    min-width: 412px
    +max-screen($breakpoint-md)
      margin-left: 0
    +max-screen($breakpoint-md)
      min-width: initial

  &__image
    width: 100%
    min-height: 180px
    max-height: 200px
    border-radius: 10px
    background-size: cover

  &__services
    display: flex
    align-items: center
    flex-direction: row-reverse

  &__services-item
    margin-right: 8px

  &__content-title
    margin-top: 20px
    +typography(title, medium)
    color: $deep-cove
    +max-screen($breakpoint-sm)
      +typography(title, small)

  &__content
    +typography(text, xlarge)
    font-weight: 400
    color: $east-bay
    margin-top: 20px

    p
      padding-bottom: 15px

    ul, ol
      margin: 10px 5px

    ul > li
      list-style: disc
      margin-left: 20px

    ol > li
      list-style: decimal
      margin-left: 20px

    th, td
      padding: 8px

  &__content_point_name
    margin-top: 25px
    margin-bottom: 10px
    +typography(text, xlarge)
    font-weight: 700

  &__content_point_description
    margin: 0 5px 10px 5px

  &__content_subpoint_description
    margin: 5px 5px 10px 10px

  &__suggestion_title
    +typography(title, small)
    color: $deep-cove
    +max-screen($breakpoint-sm)
      margin-top: 20px
      +typography(title, xsmall)

  &__suggestion_content
    margin-top: 20px

.promo-cards
  &__item
    margin: 20px 0
