.react-autosuggest

  &__container
    position: relative
    width: 50%
    +max-screen($breakpoint-xs)
      width: 100%

    &:before
      content: ''
      position: absolute
      top: 17px
      left: 12px
      width: 21px
      height: 21px
      background: url('/images/search.svg') no-repeat center / contain


  &__input
    width: 100%
    height: 52px
    padding: 10px 20px 10px 40px
    border-radius: 4px
    +typography(text, medium)

    &--focused
      outline: none

    &--open
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0

  &__suggestions-container
    display: none

    &--open
      display: block
      position: absolute
      z-index: 2
      top: 51px
      width: 100%
      background-color: $white
      border-radius: 4px
      margin-top: 8px
      box-shadow: 0 20px 90px rgba(67, 73, 123, 0.2)
      +typography(text, medium)
      color: $light-gray

  &__suggestions-list
    max-height: 320px
    overflow-y: auto
    margin: 0
    padding: 0
    list-style-type: none

  &__suggestion
    cursor: pointer
    padding: 16px 20px

    &:hover
      color: $east-bay

    &:first-child
      color: $east-bay
      padding-top: 31px

    &:last-child
      padding-bottom: 31px

    &--highlighted
      background-color: $white
