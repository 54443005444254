@keyframes close-cursor-on
	0%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAARUlEQVQ4je3OwQkAIAxDUXEBR+moHaUjuIqbfC+9CgW9mQeBXhLamojcARwYGX85bMDMWKXTi9vrcN8BIr82IJ4Ni/xqA0FQKnNe7/DkAAAAAElFTkSuQmCC') 10 10, default
	8%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADXRSTlMA319P738/H7+fLw9vZfMABQAAADpJREFUGNNjoCYwQ2IrIrEZFRiqYGwmgZVX4RKCngUwpsXFDTCmoWhJAoytxMDagDCI2w3J1MPUdDoA440H27mb/OwAAAAASUVORK5CYII=') 10 10, default
	16%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADXRSTlMAvz9fL8/fr49/H29PQCZMEQAAAEdJREFUGNNjIAcwGyDYPAUINpMAkqILSOwsJLYQA4MKlMlS3pgrAWZZil5N3LSAAcJWYVBEUu+KxOZFYrM6INiMCiiOIwMAADujB/JYKigQAAAAAElFTkSuQmCC') 10 10, default
	24%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAP79/78+fXy8P3x+vj29P3AkiVwAAAFlJREFUGNOdkUkOACEIBO12RWfh/6+duZJgjNaBhAqH7hCOAVx9P66mf52bqzVsaNCsA83TlVkTGVkgwSJAZGfSzBJtzuv9R0M1etomyUbs0RdtDLXMnnDKB1pHAVrINtE2AAAAAElFTkSuQmCC') 10 10, default
	32%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMA3+8fD59fzy+vj29Pv38/IW3oDAAAAHJJREFUGNOVkUsOwCAIRAUE/633P21j4kLbWVhW+CKTR3An5QliCxAHQ1S6RzgrzKC65Gmc3c3Ll6hMl4xO8z5cU2/mPcfPHiX0RtCOmHIE0lKVQ3mplzTCrPVkm/R8SSFaPNn9WJxvRCs+QLpwhriDegArRQIyiPXVLQAAAABJRU5ErkJggg==') 10 10, default
	40%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAD+8f379fn39PP4/Pr28vi5ORzQAAAIBJREFUGNNtkOEOxCAIg0cFQec23/9pb8l5hxr4VZvA13rsU+gIJvUU2ZdGbsW4QcutZkNkZHczO5pbHZJQpk3D9cWfsoBIu1EU7pEXIacbjmCEBdE1aHhzUti+QKhvSWFvMYfLLDX6OTI4Yg6X9F+2LOGe38NJ81hzvYQL7Xt3PnK9AniVzdzqAAAAAElFTkSuQmCC') 10 10, default
	48%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAA1UlEQVQ4ja2VQRGFMAxEcYAEJFQCUpCAFCRUChIqAQlIeP+yzJRCIc2n1yQvyW477bqPDjB8xTpBgR0In3UDeiABS63b5ARHYK0FR8GX24Q6dAY2oH9KGrTS+ph4HSa85R56RU1RLVBeu3xasVqozWITNCu+1V0bJRc0g5x0ByY1e/XAAs91x2RWI/w4rvteAydNHSSFz7gCejIre8LJrfWTWWpoeyBFYXgzS43tumvdDZiNA9h01701G2TSHVi8xmS6D2Vgug20wa+/iKCjF2rv9sf5AYeUeVA3l2EXAAAAAElFTkSuQmCC') 10 10, default
	56%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAmElEQVQ4jbXT0Q2FIAyFYeMCjuAIjHBHcARHcwRHcBU3+e+LDw0BPLTYJ1LoF0jLNH0ZwDrISUCyiR04BqAXsOQbbryKRvBX1IPLaIafrQJg6UKV2xg0lWpdeBgt4cPQAi6js2jflbU/7PO7x0tBTS6GtxoVwp8PUm2UwfUJAQ5gF87p46eiXXgvKuFetIkDvwia4VvUacYfIqtJqkkMpWwAAAAASUVORK5CYII=') 10 10, default
	64%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAuklEQVQ4jbXUUQ3EIAyA4eYMVMJJQMJJmASkTMIkTAISZuWc/PfSJYQ7BqNcE5KlG18YtIj8M4AXsE+ynmUiAqsTDcABaPliB+IgGoH0hXpwQ9tbafgyFc0mHEBofLMBWzdqk/QK95xHFXehP3C1kdxohp/12dz3Mx6d9rvyPB7ZaoONNBPVLHevbntQNw4sNbTA+5vjzmqs89qlN/KLzWYB1tFDqeIzWtQ6MuQJ7b0iG/DlpTUDVxGRD1aeLNKhiParAAAAAElFTkSuQmCC') 10 10, default
	72%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADnRSTlMAvw8vn18fT++Pz38/r3x4J4IAAACISURBVBjTY2BIYIADJjEGBEhUgAr6MDAwC0DYigeARAmYySwMIlkngEhDiNKpQMwuBFHJUsDAEAIzSpiBcwKMrZjgCreCTbAAwZYwgLMNsxeBaYh5XhDTweYxOkCY3ECaaQmEDTZPIwBE6oJJ5laQs4UhsicMgOZBjWZtZmBQgpm8SwHhV84CAHsZD90cNICsAAAAAElFTkSuQmCC') default
	80%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAAC3RSTlMAX58Pvy+vj39PH9fFs6sAAABxSURBVBjTY0AGzAVIHHEozZrAwMAuAGFPMQASimAmowKIZAJLCEFkU4GYQwHCNgkAKoQZ6wpWB5UwToRbweoSgLDbxQDODglNgCtxZhCCCxuAbAUDTme4C0HyUBdCpKEWNwEx2IVgDHchF9whBsheBwA43AnkMG/v0gAAAABJRU5ErkJggg==') 10 10, default
	88%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAJFBMVEUAAAD///////////////////////////////////////////+0CY3pAAAADHRSTlMAD79fL5+Pf08/rx9bGlJaAAAAcUlEQVQY02NABowNSJxgKM1cwMDACpUwBREqYCZbAogUckAoZQRJsBoACahEMMxYRQZRA5ixToKacCtYlBwQdisJwNlCLopwJSoMXnCtAgzMAVBhkAJTuDADVIJTEeFMU4h57AYgSYSfuGGmsQkgex0AbzsJ20LwKR0AAAAASUVORK5CYII=') 10 10, default
	96%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAlklEQVQ4jbXT3Q2AIAxGUeMCjOAIjuAIjsBojOAIruIm1xdMakKxKbVPhJ8TyFem6e8Clj/QBJzAOugUYAvFK5q1RRfeRb24CW3g6WPPYUbFwVXDh8Nu4VEd9MLD0AZuRmejfSljf8nn9wJ1o2JuDO8F5cbFQTWouufwoJ+3ATJQQlEzPhKKike0URN/vqsXFc4e4XTrBlQ3QrLzGqJ/AAAAAElFTkSuQmCC') 10 10, default
	100%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAe0lEQVQ4jdXTsQ3AIAwEQJQFGCEjsH+VVbLJp6XA9vvtJl8hhE5Cfo/xywCYAB4As90AsFR8Q5f1II2HqILTaAZPowwuox5eRk94G3rAafQi7dc469m/X1kiE93uarg3KBlnpp/GM5WicaWnIV4pv4l3bJSH3yraaYT5AOo3VBrbr7AjAAAAAElFTkSuQmCC') 10 10, default

@keyframes close-cursor-off
	0%
		cursor: default
	10%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADXRSTlMA319P738/H7+fLw9vZfMABQAAADpJREFUGNNjoCYwQ2IrIrEZFRiqYGwmgZVX4RKCngUwpsXFDTCmoWhJAoytxMDagDCI2w3J1MPUdDoA440H27mb/OwAAAAASUVORK5CYII=') 10 10, default
	20%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADXRSTlMAvz9fL8/fr49/H29PQCZMEQAAAEdJREFUGNNjIAcwGyDYPAUINpMAkqILSOwsJLYQA4MKlMlS3pgrAWZZil5N3LSAAcJWYVBEUu+KxOZFYrM6INiMCiiOIwMAADujB/JYKigQAAAAAElFTkSuQmCC') 10 10, default
	30%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAP79/78+fXy8P3x+vj29P3AkiVwAAAFlJREFUGNOdkUkOACEIBO12RWfh/6+duZJgjNaBhAqH7hCOAVx9P66mf52bqzVsaNCsA83TlVkTGVkgwSJAZGfSzBJtzuv9R0M1etomyUbs0RdtDLXMnnDKB1pHAVrINtE2AAAAAElFTkSuQmCC') 10 10, default
	40%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMA3+8fD59fzy+vj29Pv38/IW3oDAAAAHJJREFUGNOVkUsOwCAIRAUE/633P21j4kLbWVhW+CKTR3An5QliCxAHQ1S6RzgrzKC65Gmc3c3Ll6hMl4xO8z5cU2/mPcfPHiX0RtCOmHIE0lKVQ3mplzTCrPVkm/R8SSFaPNn9WJxvRCs+QLpwhriDegArRQIyiPXVLQAAAABJRU5ErkJggg==') 10 10, default
	50%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAD+8f379fn39PP4/Pr28vi5ORzQAAAIBJREFUGNNtkOEOxCAIg0cFQec23/9pb8l5hxr4VZvA13rsU+gIJvUU2ZdGbsW4QcutZkNkZHczO5pbHZJQpk3D9cWfsoBIu1EU7pEXIacbjmCEBdE1aHhzUti+QKhvSWFvMYfLLDX6OTI4Yg6X9F+2LOGe38NJ81hzvYQL7Xt3PnK9AniVzdzqAAAAAElFTkSuQmCC') 10 10, default
	60%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAA1UlEQVQ4ja2VQRGFMAxEcYAEJFQCUpCAFCRUChIqAQlIeP+yzJRCIc2n1yQvyW477bqPDjB8xTpBgR0In3UDeiABS63b5ARHYK0FR8GX24Q6dAY2oH9KGrTS+ph4HSa85R56RU1RLVBeu3xasVqozWITNCu+1V0bJRc0g5x0ByY1e/XAAs91x2RWI/w4rvteAydNHSSFz7gCejIre8LJrfWTWWpoeyBFYXgzS43tumvdDZiNA9h01701G2TSHVi8xmS6D2Vgug20wa+/iKCjF2rv9sf5AYeUeVA3l2EXAAAAAElFTkSuQmCC') 10 10, default
	70%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAmElEQVQ4jbXT0Q2FIAyFYeMCjuAIjHBHcARHcwRHcBU3+e+LDw0BPLTYJ1LoF0jLNH0ZwDrISUCyiR04BqAXsOQbbryKRvBX1IPLaIafrQJg6UKV2xg0lWpdeBgt4cPQAi6js2jflbU/7PO7x0tBTS6GtxoVwp8PUm2UwfUJAQ5gF87p46eiXXgvKuFetIkDvwia4VvUacYfIqtJqkkMpWwAAAAASUVORK5CYII=') 10 10, default
	80%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAuklEQVQ4jbXUUQ3EIAyA4eYMVMJJQMJJmASkTMIkTAISZuWc/PfSJYQ7BqNcE5KlG18YtIj8M4AXsE+ynmUiAqsTDcABaPliB+IgGoH0hXpwQ9tbafgyFc0mHEBofLMBWzdqk/QK95xHFXehP3C1kdxohp/12dz3Mx6d9rvyPB7ZaoONNBPVLHevbntQNw4sNbTA+5vjzmqs89qlN/KLzWYB1tFDqeIzWtQ6MuQJ7b0iG/DlpTUDVxGRD1aeLNKhiParAAAAAElFTkSuQmCC') 10 10, default
	90%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADnRSTlMAvw8vn18fT++Pz38/r3x4J4IAAACISURBVBjTY2BIYIADJjEGBEhUgAr6MDAwC0DYigeARAmYySwMIlkngEhDiNKpQMwuBFHJUsDAEAIzSpiBcwKMrZjgCreCTbAAwZYwgLMNsxeBaYh5XhDTweYxOkCY3ECaaQmEDTZPIwBE6oJJ5laQs4UhsicMgOZBjWZtZmBQgpm8SwHhV84CAHsZD90cNICsAAAAAElFTkSuQmCC') default
	100%
		cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAAC3RSTlMAX58Pvy+vj39PH9fFs6sAAABxSURBVBjTY0AGzAVIHHEozZrAwMAuAGFPMQASimAmowKIZAJLCEFkU4GYQwHCNgkAKoQZ6wpWB5UwToRbweoSgLDbxQDODglNgCtxZhCCCxuAbAUDTme4C0HyUBdCpKEWNwEx2IVgDHchF9whBsheBwA43AnkMG/v0gAAAABJRU5ErkJggg==') 10 10, default