.page-header
	$parent-selector: &
	position: relative
	margin-bottom: 30px
	padding: 18px 0 20px
	background-color: $backgroundDark
	+min-screen($breakpoint-xs)
		margin-bottom: 60px
		padding-top: 36px
	&._large
		margin-bottom: -153px
		height: 340px
		+min-screen($breakpoint-xs)
			margin-bottom: -153px

	&__container
		@extend %container

	&__logo
		margin-right: 12px

	&__main
		padding-bottom: 18px
		display: flex
		justify-content: space-between
		border-bottom: 1px solid rgba($backgroundGray, .4)
		+min-screen($breakpoint-xs)
			padding-bottom: 16px

	&__navbar
		flex-grow: 1

	&__tickers
		margin-top: 20px
