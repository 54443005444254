.form-control
    position: relative
    display: block

    &__placeholder
        +typography(text, medium)
        position: absolute
        top: 19px
        left: 0
        padding: 0 60px 0 20px
        width: 100%
        color: $light-gray
        pointer-events: none
        will-change: transform
        overflow: hidden
        text-overflow: ellipsis
        transition: transform .2s, font-size .2s, color .2s

    &._focus &__placeholder
        +typography(caption, medium)
        white-space: nowrap
        transform: translateY(-8px)

    &__field
        padding: 0 60px 0 20px
        display: block
        +size(100%, 52px)
        font: inherit
        +typography(text, medium)
        color: $east-bay
        //background-color: $gray
        background-repeat: no-repeat
        background-position: center right 20px
        background-size: 21px
        border: 1px solid $gray
        border-radius: $border-radius

        &._valid,
        &:valid
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAuNSIgY3k9IjEwLjUiIHI9IjEwIiBzdHJva2U9IiM1RUNCQTEiLz48cGF0aCBkPSJNNy41IDEwbDMgM0wxNSA4LjUiIHN0cm9rZT0iIzVFQ0JBMSIvPjwvc3ZnPg==)
            border-color: $green

        &._invalid
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAuNSIgY3k9IjEwLjUiIHI9IjEwIiBzdHJva2U9IiNGRjZCNjkiLz48cGF0aCBkPSJNMTEuMDQgMTIuMzZMMTEuMzE2IDlWNi42aC0xLjU2VjlsLjI2NCAzLjM2aDEuMDJ6bS0uNTA0Ljk4NGMtLjUxNiAwLS45LjQwOC0uOS45IDAgLjQ5Mi40MDguOS45LjkuNDkyIDAgLjktLjQwOC45LS45IDAtLjQ5Mi0uNDA4LS45LS45LS45eiIgZmlsbD0iI0ZGNkI2OSIvPjwvc3ZnPg==)
            border-color: $red

        &._disabled
            background: $gray
            opacity: 0.4

        &._textarea
            padding: 25px 60px 20px 20px
            height: auto
            min-height: 52px
            resize: vertical
            background-position: top 20px right 20px

        &::placeholder
            color: transparent
            opacity: 0

    &._light &__field
        background-color: #ffffff
        border-color: $gray

        &._valid,
        &:valid
            border-color: $green

        &._invalid
            border-color: $red

    &._small &__field
        height: 39px

    &._small &__placeholder
        top: 11px

    &._small._focus &__field
        padding-top: 12px

    &._focus &__field
        padding-top: 10px

        &._textarea
            padding-top: 25px

    &__message
        margin-top: 4px
        display: inline-block
        +typography(text, xsmall)
        color: $red

        &:empty
            display: none

.form-control-group
    display: flex
    flex-direction: row
    justify-content: space-between

    &__item
        width: 48%
