%container
    padding-left: 20px
    padding-right: 20px
    margin-left: auto
    margin-right: auto
    width: 100%
    max-width: 1400px
    +min-screen($breakpoint-xs)
        padding-left: 60px
        padding-right: 60px

.h1
    +typography(title, xlarge)

.h2
    +typography(title, large)

.h3
    +typography(title, medium)

.h4
    +typography(title, small)

.h5
    +typography(title, xsmall)

.h6
    +typography(title, xxsmall)

.swiper-container
    margin: 0 auto
    position: relative
    overflow: hidden
    list-style: none
    padding: 0
    z-index: 1

.swiper-wrapper
    position: relative
    z-index: 1
    +size(100%)
    display: flex
    transition-property: transform

    .swiper-container-vertical > &
        flex-direction: column

.swiper-slide
    position: relative
    flex-shrink: 0
    +size(100%)
    transition-property: transform

.swiper-pagination
    position: absolute
    text-align: center
    transition: 300ms opacity
    transform: translate3d(0, 0, 0)
    z-index: 10

    &.swiper-pagination-hidden
        opacity: 0

.swiper-pagination-bullet
    $breakpoint-sm: 980px

    position: relative
    +size(8px)
    display: inline-block
    border-radius: 100%
    background-color: $gray
    @extend .animated
    +min-screen($breakpoint-sm)
        display: block

    &:hover
        background-color: $deep-cove

    &::before
        position: absolute
        top: calc(50% - 15px)
        left: calc(50% - 15px)
        content: ''
        +size(30px)

    &:not(:last-child)
        margin: 0 18px 0 0
        +min-screen($breakpoint-sm)
            margin: 0 0 18px 0

    .swiper-pagination-clickable &
        cursor: pointer

.swiper-pagination-bullets
    $breakpoint-sm: 980px

    left: 50%
    bottom: 30px
    margin: 6px 0
    display: block
    transform: translate3d(-50%, 0, 0)
    +min-screen($breakpoint-sm)
        top: 50%
        left: auto
        right: 40px
        bottom: auto
        transform: translate3d(0, -50%, 0)

.swiper-pagination-bullet-active
    background-color: $deep-cove

.invisible-container
    position: absolute
    top: -9999px
    left: -9999px
    width: 0
    height: 0
    overflow: hidden
    z-index: -1

.animated
    @extend .transition-active

    &:hover
        @extend .transition-hover

.transition-active
    transition: color $default-transition-duration $default-transition-function, background $default-transition-duration $default-transition-function, border-color $default-transition-duration $default-transition-function

.transition-hover
    transition: .2s

.rise
    color: $green

.fall
    color: $red

.scrollbar
    &::-webkit-scrollbar
        width: 6px
        height: 4px
        background: transparent

    &::-webkit-scrollbar-button
        display: none

    &::-webkit-scrollbar-track
        background: transparent

    &::-webkit-scrollbar-thumb
        background: $gray

.enter-animation
    transform: translateY(30px)
    opacity: 0

    &--active
        position: relative
        transition: opacity .7s, transform .7s
        transform: translateY(0)
        opacity: 1

.no-hover
    pointer-events: none

.timer
    cursor: initial
    color: $light-gray
    +typography(text, small)

.error-message
    margin-top: 10px
    margin-bottom: 10px
    color: $red
    text-align: center

.footnote
    color: #000
    font-size: 15px

.crossed
    text-decoration: line-through

.link
    text-decoration: underline

html.i-amphtml-fie>body.page, 
html.i-amphtml-singledoc>body.page 
    overflow: hidden !important

.errorH1
    text-align: center
    font-size: 30px
    font-weight: 600
    margin-bottom: 20px

.errorH2
    text-align: center
    font-size: 22px
    font-weight: 600
    margin-bottom: 20px
