.forecast
	color: $deep-cove

	&__header
		margin-bottom: 35px

	&__value
		position: relative
		padding-left: 40px
		+typography(title, medium)
		&::before
			position: absolute
			top: calc(50% - 15px)
			left: 0
			content: ''
			+size(30px)
			background-color: currentColor
			background-position: center
			background-repeat: no-repeat
			border-radius: 50%
		&._up
			color: $green
			&::before
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4xMzQuNWExIDEgMCAwIDEgMS43MzIgMGwzLjQ2NCA2QTEgMSAwIDAgMSA4LjQ2NCA4SDEuNTM2QTEgMSAwIDAgMSAuNjcgNi41bDMuNDY0LTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+)
		&._down
			color: $red
			&::before
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS44NjYgNy41YTEgMSAwIDAgMS0xLjczMiAwTC42NyAxLjVBMSAxIDAgMCAxIDEuNTM2IDBoNi45MjhhMSAxIDAgMCAxIC44NjYgMS41bC0zLjQ2NCA2eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==)

	&__title
		+typography(title, small)
		height: (28px *.92857 * 2)
		overflow: hidden

	&__desc
		margin: 30px 0 18px
		+typography(text, xlarge)
		color: $east-bay

	&__date
		+typography(text, xxsmall)
		font-weight: 600
		color: $light-gray
