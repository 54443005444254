.forecasts
	&__list
		margin: -40px 0 0
		+min-screen($breakpoint-md)
			margin: 0 -15px
			display: flex

	&__item
		padding: 40px 0 0
		+min-screen($breakpoint-md)
			padding: 0 15px
			width: (100% / 3)
