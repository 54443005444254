.securities
    &__header
        +typography(text, xlarge)

    &__group-container
        margin: 35px 0

    &__group
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        &:hover
            cursor: pointer

    &__group-name
        font-weight: bold

    &__group-icon
        width: 18px
        min-width: 18px
        height: 18px
        transition: all .3s
        margin-left: 16px
        transform: rotate(-45deg)

        svg
            width: 100%
            height: 100%

        &_active
            transform: rotate(0deg)

    &__group-list
        display: grid
        grid-template-columns: 22% 22% 22% 22%
        margin: 20px

    &__group-list-item
        +typography(text, medium)

