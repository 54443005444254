.contact
	color: $deep-cove

	&__title
		+typography(text, medium)
		text-transform: uppercase

	&__phone
		+typography(title, small)
		line-height: (46 / 28)
		text-decoration: none
		color: inherit
		@extend .animated
		&:hover
			color: $east-bay
