.personal-page
  &__profile
    display: flex
    flex-direction: row
    align-items: baseline
    justify-content: flex-start
    color: $light-gray
    margin: 10px 0 -10px 0

    +max-screen($breakpoint-xs)
      flex-direction: column

  &__profile-name
    +typography(text, xlarge)
    color: $east-bay
  &__profile-link
    +typography(text, medium)
    text-decoration: none
    margin-left: 10px
    color: $green

    +max-screen($breakpoint-xs)
      margin: 7px 0

  &__accounts
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-end
    padding: 10px 30px 5px 0
    margin: 10px 0 -26px 16px

  &__accounts-tab
    +typography(text, large)
    cursor: pointer
    margin-right: 12px
    padding: 4px 8px 0

    &._active
      color: $green
      border-bottom: 1px solid $green

  &__accounts-refetch
    margin-left: auto
    align-self: center
