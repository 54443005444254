.section
    $parent-selector: &
    padding: 0 0 40px
    color: $deep-cove
    +min-screen($breakpoint-xs)
        padding: 0 0 90px

    &._dark
        position: relative
        color: #fff
        background-color: $deep-cove

        &::before
            position: absolute
            z-index: -1
            top: 0
            left: 50%
            content: ''
            margin-left: -50vw
            +size(100vw, 100%)
            background-color: inherit

    &._howto
        padding: 75px 0 90px
        +min-screen($breakpoint-xs)
            padding: 75px 0 180px

    &._form
        position: relative
        z-index: 2
        padding: 0
        margin-top: -60px
        margin-bottom: 40px
        +min-screen($breakpoint-xs)
            margin-bottom: 97px

    &._request
        padding-bottom: 0
        color: #fff

        #{$parent-selector}
            &__content
                > *
                    position: relative
                &:before
                    content: ''
                    position: absolute
                    width: 450px
                    height: 450px
                    bottom: -20px
                    right: 45px
                    transform: rotate(-90deg)
                    opacity: 0.8
                    background: url('/images/main-slider/bg-1.svg') no-repeat center / contain

                +max-screen($breakpoint-xs - 1)
                    &:before
                        width: 300px
                        height: 300px

    &__container
        @extend %container

    &__inner
        margin: 0 -20px
        padding: 20px
        background-color: #fff
        border-radius: 20px
        box-shadow: 0 20px 90px rgba($east-bay, .2)
        +min-screen($breakpoint-xs)
            margin: 0
        +min-screen($breakpoint-sm)
            padding: 60px
        +min-screen($breakpoint-md)
            display: flex

    &__header
        margin-bottom: 21px
        display: flex
        flex-wrap: wrap
        align-items: center
        +min-screen($breakpoint-xs)
            align-items: flex-end

        &._divider
            padding: 0 12px 20px 0
            margin-bottom: 42px
            border-bottom: 1px solid $gray

    &._strategies &__header
        +max-screen($breakpoint-sm - 1)
            justify-content: center
            text-align: center

    &._form &__content
        +min-screen($breakpoint-md)
            max-width: 400px

    &._request &__content
        margin: 0 -20px
        padding: 80px 20px
        text-align: center
        background-color: $deep-cove
        +min-screen($breakpoint-xs)
            margin: 0
            padding: 0 70px
            display: inline-flex
            flex-direction: column
            align-items: flex-start
            justify-content: center
            text-align: left
            +size(100%, 480px)
            border-radius: 20px

    &__title
        +typography(title, xsmall)
        +min-screen($breakpoint-xs)
            +typography(title, large)

    &._form &__title
        margin-bottom: 30px
        text-align: center
        +min-screen($breakpoint-md)
            text-align: left

    &__desc
        margin-bottom: 60px
        +typography(title, xsmall)
        max-width: 766px
        color: $east-bay

    &._dark &__desc
        color: #fff

    &._form &__desc
        +typography(text, xlarge)
        text-align: center
        +min-screen($breakpoint-md)
            text-align: left

    &._request &__desc
        margin: 27px auto 60px
        max-width: 320px
        color: #fff
        +min-screen($breakpoint-xs)
            margin-left: 0

    &__link
        margin-left: auto
        font-weight: 700
        color: $deep-cove
        text-decoration: none
        @extend .animated

        &.decorated
            text-decoration: underline

        &:hover,
        &:visited
            color: $east-bay

    &__form
        margin-right: auto
        +min-screen($breakpoint-xs)
            margin-left: auto
            width: 500px
            flex-shrink: 0
        +min-screen($breakpoint-md)
            margin-right: 0

    &__caption
        margin-top: 10px
        width: 100%
        +typography(text, xsmall)
        color: $light-gray
        +min-screen($breakpoint-sm)
            margin-top: 20px

    &__sort
        display: none
        +min-screen($breakpoint-sm)
            padding-right: 20px
            margin-left: auto
            display: block
            +typography(text, medium)
            color: $deep-cove
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMUw1LjUgNS41IDEgMSIgc3Ryb2tlPSIjMDUwQzQyIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=)
            background-position: center right
            background-repeat: no-repeat

    &__subtitle
        text-align: center
        margin-bottom: 10px
        +typography(title, large)
        +min-screen($breakpoint-xs)
            +typography(title, small)

    &__success
        text-align: center
        color: $green
