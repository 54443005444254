.filter

    &__search
        width: 100%
        margin-top: 20px

    &__field
        padding: 0 20px 0 50px
        display: block
        flex-grow: 1
        width: 100%
        height: 52px
        font: inherit
        +typography(text, medium)
        color: $east-bay
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTguMDMxIDE2LjYxN0wyMi4zMTQgMjAuODk5TDIwLjg5OSAyMi4zMTRMMTYuNjE3IDE4LjAzMUMxNS4wMjM3IDE5LjMwODIgMTMuMDQyIDIwLjAwMjkgMTEgMjBDNi4wMzIgMjAgMiAxNS45NjggMiAxMUMyIDYuMDMyIDYuMDMyIDIgMTEgMkMxNS45NjggMiAyMCA2LjAzMiAyMCAxMUMyMC4wMDI5IDEzLjA0MiAxOS4zMDgyIDE1LjAyMzcgMTguMDMxIDE2LjYxN1pNMTYuMDI1IDE1Ljg3NUMxNy4yOTQxIDE0LjU2OTkgMTguMDAyOSAxMi44MjA0IDE4IDExQzE4IDcuMTMyIDE0Ljg2NyA0IDExIDRDNy4xMzIgNCA0IDcuMTMyIDQgMTFDNCAxNC44NjcgNy4xMzIgMTggMTEgMThDMTIuODIwNCAxOC4wMDI5IDE0LjU2OTkgMTcuMjk0MSAxNS44NzUgMTYuMDI1TDE2LjAyNSAxNS44NzVaIiBmaWxsPSIjOTU5OEE3Ii8+PC9zdmc+)
        background-position: left 14px center
        background-repeat: no-repeat
        background-color: transparent
        border: 1px solid $gray
        border-radius: $border-radius
        +min-screen($breakpoint-sm)
            width: 420px
            margin-right: 20px

        &::placeholder
            color: $light-gray
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            +max-screen($breakpoint-xs - 1)
                opacity: 0

    &__categories
        display: flex
        align-items: baseline
        width: 100%
        margin-top: 20px
        padding-bottom: 10px
        color: $east-bay
        +max-screen($breakpoint-xs)
            justify-content: space-between

        &-label
            font-weight: bold

        &-list
            display: flex
            flex-wrap: wrap
            margin-left: 10px
            +max-screen($breakpoint-xs)
                display: initial

        &-item
            margin: 10px 10px 0
            cursor: pointer
            height: 19px
            line-height: 19px
            +max-screen($breakpoint-xs)
                text-align: right

            &-active
                text-decoration: underline

            &-sort
                margin-left: 2px
