.modal
    $parent-selector: &
    display: none
    color: $deep-cove
    cursor: default

    &.is-open
        display: block
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAe0lEQVQ4jdXTsQ3AIAwEQJQFGCEjsH+VVbLJp6XA9vvtJl8hhE5Cfo/xywCYAB4As90AsFR8Q5f1II2HqILTaAZPowwuox5eRk94G3rAafQi7dc469m/X1kiE93uarg3KBlnpp/GM5WicaWnIV4pv4l3bJSH3yraaYT5AOo3VBrbr7AjAAAAAElFTkSuQmCC') 10 10, default
        animation: close-cursor-on 420ms


    ._modal-animation--closing &
        animation: close-cursor-off 350ms
        animation-direction: reverse


    &__overlay
        position: fixed
        z-index: 999
        top: 0
        left: 0
        width: 100vw
        bottom: 0
        background: rgba($deep-cove, .5)
        display: flex
        justify-content: center
        align-items: center


    &__container
        position: relative
        padding: 60px 20px
        width: 100%
        max-width: 728px
        max-height: 100vh
        overflow-y: auto
        background-color: #fff
        cursor: initial
        +max-screen($breakpoint-xs - 1)
            height: 100%
        +min-screen($breakpoint-xs)
            padding: 60px 115px
            border-radius: 20px
            box-shadow: 0 20px 90px rgba($east-bay, .2)


    &__header
        margin-bottom: 28px
        text-align: center


    &__title
        margin-bottom: 28px
        +typography(text, xlarge)
        +max-screen($breakpoint-xs)
            +typography(text, xlarge)


    &__desc
        margin: 0 auto
        width: 100%
        max-width: 400px
        +typography(text, xlarge)


    &__text
        margin: 20px 0
        +typography(text, xlarge)
        text-align: center
        +min-screen($breakpoint-xs)
            margin: 30px 0


    &__link
        color: $green
        cursor: pointer


    &__close
        position: absolute
        top: 20px
        right: 20px
        display: block
        color: $light-gray
        +hide-text
        +size(16px)
        +min-screen($breakpoint-xs)
            top: 30px
            right: 30px

        &::before,
        &::after
            position: absolute
            left: 50%
            content: ''
            +size(100%, 2px)
            background-color: currentColor
            transition: width $default-transition-duration $default-transition-function

        &::before
            top: 50%
            transform: translate(-50%, -50%) rotate(45deg)

        &::after
            bottom: 50%
            transform: translate(-50%, 50%) rotate(-45deg)


    &__text-content
        +typography(text, small)
        color: $east-bay
        margin-bottom: 26px
        +min-screen($breakpoint-xs)
            +typography(text, xlarge)
            font-weight: normal
            margin-bottom: 32px
    
    &__text-content-warning
        +typography(text, small)
        color: $red
        margin-bottom: 26px
        +min-screen($breakpoint-xs)
            +typography(text, xlarge)
            font-weight: normal
            margin-bottom: 32px

    &__subtitle
        +typography(text, medium)
        margin-bottom: 24px
        +min-screen($breakpoint-xs)
            margin-bottom: 42px


    &__form-recommendations
        +min-screen($breakpoint-xs)
            min-height: 400px
            display: flex
            flex-direction: column
            .form__group:not(:last-child)
                margin-bottom: 24px
            .form__group:last-child
                margin-top: auto


    &__text-title
        margin-bottom: 17px
        +typography(title, xxsmall)


    &__text-wrap
        margin-bottom: 24px

    &__text-block
        +typography(text, small)

        p
            margin-bottom: 19px

            &:last-child
                margin-bottom: 0


    &__label
        margin-bottom: 12px
        +typography(text, medium)


    &__logo
        position: absolute
        top: 10px
        left: 10px


    &._v2
        #{$parent-selector}
            &__title
                text-align: left
                margin-bottom: 24px
                +min-screen($breakpoint-xs)
                    +typography(title, small)

            &__container
                max-height: calc(100vh - 40px)
                padding: 50px 30px 30px
                border-radius: 20px
                box-shadow: 0 20px 90px rgba(67, 73, 123, 0.2)
                +min-screen($breakpoint-xs)
                    padding: 50px
                +max-screen($breakpoint-xs - 1)
                    height: auto

            &__overlay
                padding: 20px

            &__content
                overflow: hidden


    &._no-title
        #{$parent-selector}
            &__title
                display: none

            &__header
                margin-bottom: 0


    +min-screen($breakpoint-xs)
        &__text-wrap
            margin-bottom: 30px
            padding: 18px 0
            background-color: rgba(242, 243, 251, 0.6)
            border-radius: 20px
        &__text-block
            height: 214px
            overflow-y: auto


[aria-hidden="false"] .modal__overlay
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1)

[aria-hidden="false"] .modal__container
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1)

[aria-hidden="true"] .modal__overlay
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1)

[aria-hidden="true"] .modal__container
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1)

@keyframes mmfadeIn
    from
        opacity: 0
    to
        opacity: 1

@keyframes mmfadeOut
    from
        opacity: 1
    to
        opacity: 0

@keyframes mmslideIn
    from
        transform: translateY(15%)
    to
        transform: translateY(0)

@keyframes mmslideOut
    from
        transform: translateY(0)
    to
        transform: translateY(-10%)
