.promo

    &__section
        margin-top: 0 !important

    &__section-marketplace
        position: relative
        padding-top: 60px
        margin-bottom: 90px
        color: $white

        &:before
            position: absolute
            z-index: -1
            top: 0
            left: 50%
            content: ""
            margin-left: -50vw
            width: 100vw
            height: 100%
            background-color: $violet

    &__section-header
        display: block

    &__section-subtitle
        margin-top: 10px

    &__marketplace
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-top: 50px
        +max-screen($breakpoint-sm)
            flex-direction: column
            align-items: center

    &__marketplace-list
        width: 40%
        flex-shrink: 0
        padding: 10px 20px
        +max-screen($breakpoint-sm)
            width: 100%

    &__marketplace-list-item
        padding: 10px 0
        cursor: pointer
        list-style: disc
        +typography(text, xlarge)

    &__marketplace-list-item-span

        &_active,
        &:hover
            border-bottom: 1px dashed

    &__marketplace-video
        position: relative
        height: 390px
        overflow: hidden
        margin-bottom: 25px
        +max-screen($breakpoint-md)
            height: 280px
        +max-screen($breakpoint-xs)
            height: 220px

    &__marketplace-video-description
        min-height: 50px
        margin: 0 10px

    &__marketplace-video iframe
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0

    &__waves
        position: absolute
        z-index: -1
        left: -20%
        right: 20%
        bottom: 0
        width: 140%
        fill: $white
        object-fit: cover
        pointer-events: none

    &__subtitle
        margin-top: 0

    &__button-wrapper
        text-align: center
        margin-top: 30px

    &__connections
        display: flex
        flex-direction: row
        justify-content: space-between
        +max-screen($breakpoint-sm)
            flex-direction: column
            justify-content: flex-start

    &__about-tools
        cursor: pointer

.promo-connections
    width: 33%
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 70px
    +between-screen($breakpoint-xs, $breakpoint-sm)
        width: 100%
        flex-direction: row
    +max-screen($breakpoint-xs)
        width: 100%
        align-items: center

    &__item-title
        +typography(title, small)
        margin-bottom: 10px

    &__item-text
        line-height: 25px

    &__item-description
        margin-top: 20px
        +max-screen($breakpoint-sm)
            margin-top: 0
            margin-left: 50px
        +max-screen($breakpoint-xs)
            margin-top: 20px
            margin-left: 0
            padding-left: 60px

    &__item-image
        min-width: 179px
        min-height: 119px
