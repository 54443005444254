.range-slider
    padding-bottom: 36px

    &__wrap
        padding: 0 10px

    &__title
        display: block
        margin-bottom: 20px
        +typography(text, medium)
        color: $deep-cove


    .rc-slider
        height: 17px
        $colorsRangeGradient: ($green, $green2, $green3, $yellow, $orange, $red2, $red3)

        &-mark
            top: 25px

        &-rail, &-track, &-step
            height: 7px

        &-rail
            background: linear-gradient(90deg, $colorsRangeGradient)

        &-step
            .rc-slider-dot
                width: 12px
                height: 12px
                border-radius: 50%
                pointer-events: none
                border: none
                margin-left: -6px
                @for $i from 1 to length($colorsRangeGradient) + 1
                    &:nth-child(#{$i})
                        background-color: nth(($colorsRangeGradient), $i)


        &-handle
            width: 24px
            height: 24px
            //margin-left: -12px
            margin-top: -9px
            outline: none
            border: 7px solid $green
            @for $i from 1 to length($colorsRangeGradient) + 1
                &[aria-valuenow='#{$i}']
                    border-color: nth(($colorsRangeGradient), $i)

    &._range
        .rc-slider
            &-rail
                background: $gray

            &-track
                background-color: $green

            &-step .rc-slider-dot
                display: none

            &-handle
                border-color: $green


    &.green
        .rc-slider
            &-handle
                border-color: $green

    &.green2
        .rc-slider
            &-handle
                border-color: $green2

    &.green3
        .rc-slider
            &-handle
                border-color: $green3

    &.yellow
        .rc-slider
            &-handle
                border-color: $yellow

    &.orange
        .rc-slider
            &-handle
                border-color: $orange

    &.red2
        .rc-slider
            &-handle
                border-color: $red2

    &.red3
        .rc-slider
            &-handle
                border-color: $red3
