.main-nav
	&__list
		display: flex
		flex-direction: column
		+min-screen($breakpoint-sm)
			height: 22px
			flex-direction: row
			align-items: center

	&__item
		white-space: nowrap
		margin: 0 0 15px 0
		+min-screen($breakpoint-sm)
			margin: 0 34px 0 0
		+min-screen($breakpoint-xl)
			margin: 0 50px 0 0

	&__link
		text-decoration: none
		color: inherit
		padding-bottom: 2px
		font-weight: 500
		@extend .animated
		@include min-screen($breakpoint-lg)
			color: $primaryGray
		&:hover
			font-weight: 700
			color: $white !important
			&~ svg 
				font-weight: 700
				color: $white !important
		+max-screen($breakpoint-lg - 1)
			&:hover
				color: inherit !important
		
		&::before
			display: block
			content: attr(title)
			font-weight: bold
			height: 0
			overflow: hidden
			visibility: hidden
		
	&__item._active
		margin-bottom: 1px

	&__item._active &__link
		font-weight: 700
		color: $white
		border-bottom: 2px solid $primaryBlue
		+max-screen($breakpoint-xs - 1)
			color: inherit

	&__item-dropdown-wrapper
		display: flex
		align-items: center
		text-align: center
		@include min-screen($breakpoint-lg)
			color: $primaryGray
		&:hover
			font-weight: 700
			color: $white !important
			&~ svg 
				font-weight: 700
				color: $white !important
		+max-screen($breakpoint-lg - 1)
			&:hover
				color: inherit !important

	&__item-dropdown
		position: relative
		margin-right: 0
		cursor: pointer
		color: inherit
		font-weight: inherit
		+max-screen($breakpoint-lg - 1)
			flex-wrap: wrap
			justify-content: center

	&__item-dropdown-icon
		color: inherit
		cursor: pointer
		transform: rotate(180deg)
		transition: transform 0.3s linear

	&__item-dropdown-icon-open
		transform: rotate(0deg)
		transition: transform 0.3s linear

	&__item-dropdown-icon-inner
		display: none
		+max-screen($breakpoint-lg - 1)
			display: inline
			color: inherit
			margin-bottom: -2px
	
	&__item-dropdown-icon-outer
		display: inline
		+max-screen($breakpoint-lg - 1)
			display: none

	&__item-dropdown-menu
		position: absolute
		top: 35px
		z-index: 10
		padding-top: 7px
		visibility: hidden
		background-color: $white
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1)
		+max-screen($breakpoint-lg - 1)
			width: 100%
			position: initial
			background-color: transparent
			box-shadow: none
			display: none
			margin-top: 10px
	
	&__item-dropdown-menu-visible
		visibility: visible
		+max-screen($breakpoint-lg - 1)
			display: block

	&__item-dropdown-menu-item
		display: flex
		justify-content: space-between
		padding: 12px 16px
		color: $primaryGray
		text-decoration: none
		font-weight: normal !important
		+max-screen($breakpoint-lg - 1)
			border: none
			justify-content: center
		&:not(:last-child)
			border-bottom: 0.5px solid rgba(229, 229, 229, 1)
			+max-screen($breakpoint-lg - 1)
				border: none
				justify-content: center
			

		&:hover
			font-weight: normal
			color: $primaryGray

	&__item-dropdown-menu-item-icon
		font-size: 16px
		margin-left: 8px
