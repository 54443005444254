.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track
    background-color: transparent

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track
    background-color: $green


.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb,
.react-toggle--focus .react-toggle-thumb
    box-shadow: none

.react-toggle
    $parent-selector: &
    &--checked
        #{$parent-selector}
            &-track
                background-color: $green
            &-thumb
                left: 16px
                border-color: $gray
    &-track
        width: 34px
        height: 20px
        background-color: transparent
        border: 1px solid $gray

    &-thumb
        width: 16px
        height: 16px
        top: 2px
        left: 2px
        border: 1px solid $gray
        background-color: $gray

