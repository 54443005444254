.selectr-container
	position: relative

.selectr-container li
	list-style: none

.selectr-hidden
	position: absolute
	overflow: hidden
	clip: rect(0, 0, 0, 0)
	+size(1px)
	margin: -1px
	padding: 0
	border: 0 none

.selectr-visible
	position: absolute
	left: 0
	top: 0
	+size(100%)
	opacity: 0
	z-index: 11

.selectr-desktop.multiple .selectr-visible
	display: none

.selectr-desktop.multiple.native-open .selectr-visible
	top: 100%
	min-height: 200px !important
	height: auto
	opacity: 1
	display: block

.selectr-container.multiple.selectr-mobile .selectr-selected
	z-index: 0

.selectr-selected
	position: relative
	z-index: 1
	box-sizing: border-box
	width: 100%
	padding: 17px 28px 17px 20px
	cursor: pointer
	border: 1px solid $gray
	border-radius: $border-radius
	background-color: #fff
	&::before
		position: absolute
		top: calc(50% - 4px)
		right: 20px
		+size(11px, 8px)
		content: ''
		background-position: center
		background-repeat: no-repeat
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA2LjVMNS41IDIgMTAgNi41IiBzdHJva2U9IiMwNTBDNDIiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==)
		transform: rotate(180deg)

._small .selectr-selected
	padding: 10px 28px 10px 20px

.selectr-container.open .selectr-selected::before,
.selectr-container.native-open .selectr-selected::before
	transform: rotate(0)

.selectr-label
	display: none
	overflow: hidden
	width: 100%
	white-space: nowrap
	text-overflow: ellipsis

.selectr-placeholder
	+typography(text, medium)
	color: $light-gray

.selectr-tags
	margin: 0
	padding: 0
	white-space: normal

.has-selected .selectr-tags
	margin: 0 0 -2px

.selectr-tag
	list-style: none
	position: relative
	float: left
	+typography(text, medium)
	margin: 0 2px 2px 0
	cursor: default
	color: $deep-cove
	&:not(:last-child)::after
		content: ', '

.selectr-container.multiple.has-selected .selectr-selected
	padding: 17px 28px 17px 20px

.selectr-options-container
	position: absolute
	z-index: 10000
	top: calc(100% + 10px)
	left: 0
	// display: none
	opacity: 0
	visibility: hidden
	transform: translateY(100%)
	box-sizing: border-box
	width: 100%
	border-radius: $border-radius
	background-color: #fff
	box-shadow: $shadow
	transition: all .2s $default-transition-function

.selectr-container.open .selectr-options-container
	// display: block
	opacity: 1
	visibility: visible
	transform: translateY(0)

.selectr-input-container
	position: relative
	display: none

.selectr-input-clear,
.selectr-tag-remove
	position: absolute
	top: 50%
	right: 22px
	+size(20px)
	padding: 0
	cursor: pointer
	transform: translate3d(0, -50%, 0)
	border: medium none
	background-color: transparent
	z-index: 11

.selectr-clear,
.selectr-input-clear
	display: none

.selectr-container.has-selected .selectr-clear,
.selectr-input-container.active .selectr-input-clear
	display: block

.selectr-tag-remove
	display: none

.selectr-selected .selectr-tag-remove
	right: 2px

.selectr-input-clear::before,
.selectr-input-clear::after,
.selectr-tag-remove::before,
.selectr-tag-remove::after
	position: absolute
	top: 5px
	left: 9px
	width: 2px
	height: 10px
	content: ' '
	background-color: #6c7a86

.selectr-tag-remove::before,
.selectr-tag-remove::after
	top: 4px
	width: 3px
	height: 12px
	background-color: #fff

.selectr-input-clear::before,
.selectr-tag-remove::before
	transform: rotate(45deg)

.selectr-input-clear::after,
.selectr-tag-remove::after
	transform: rotate(-45deg)

.selectr-input-container.active,
.selectr-input-container.active .selectr-clear
	display: block

.selectr-clear
	position: absolute
	z-index: 11
	top: calc(50% - 10px)
	right: 47px
	+size(20px)
	background-repeat: no-repeat
	background-position: center
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwIDFMNS41IDUuNSAxIDFNMSAxMGw0LjUtNC41TDEwIDEwIiBzdHJva2U9IiM5NTk4QTciIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==)

.selectr-input
	top: 5px
	left: 5px
	box-sizing: border-box
	width: calc(100% - 30px)
	margin: 10px 15px
	padding: 7px 30px 7px 9px
	border: 1px solid #999
	border-radius: 3px

.selectr-notice
	display: none
	box-sizing: border-box
	width: 100%
	padding: 8px 16px
	border-top: 1px solid #999
	border-radius: 0 0 3px 3px
	background-color: #fff

.selectr-container.notice .selectr-notice
	display: block

.selectr-container.notice .selectr-selected
	border-radius: 3px 3px 0 0

.selectr-options
	position: relative
	top: calc(100% + 2px)
	display: none
	overflow-x: auto
	overflow-y: auto
	max-height: 300px
	margin: 0
	padding: 0

.selectr-container.open .selectr-options,
.selectr-container.open .selectr-input-container,
.selectr-container.notice .selectr-options-container
	display: block

.selectr-option
	position: relative
	display: block
	padding: 20px
	list-style: outside none none
	cursor: pointer
	+typography(text, medium)
	color: $light-gray
	background-position: center right 20px
	background-repeat: no-repeat
	@extend .animated

.selectr-options.optgroups > .selectr-option
	padding-left: 25px

.selectr-optgroup
	font-weight: bold
	padding: 0

.selectr-optgroup--label
	font-weight: bold
	margin-top: 10px
	padding: 5px 15px

.selectr-match
	text-decoration: underline

.selectr-option.selected
	color: $deep-cove
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDFMNiA4IDEuNSAzLjUiIHN0cm9rZT0iIzVFQ0JBMSIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+)

.selectr-option.active
	color: $deep-cove

.selectr-option.disabled
	opacity: .4

.selectr-option.excluded
	display: none

.selectr-container.open .selectr-selected
	border-color: $violet

.selectr-container.open .selectr-selected::after
	transform: rotate(180deg) translate3d(0, 50%, 0)

.selectr-disabled
	opacity: .6

.selectr-empty,
.has-selected .selectr-placeholder
	display: none

.has-selected .selectr-label
	display: block

.taggable .selectr-selected
	padding: 4px 28px 4px 4px

.taggable .selectr-selected::after
	display: table
	content: ' '
	clear: both

.taggable .selectr-label
	width: auto

.taggable .selectr-tags
	float: left
	display: block

.taggable .selectr-placeholder
	display: none

.input-tag
	float: left
	min-width: 90px
	width: auto

.selectr-tag-input
	border: medium none
	padding: 3px 10px
	width: 100%
	font-family: inherit
	font-weight: inherit
	font-size: inherit

.selectr-input-container.loading::after
	position: absolute
	top: 50%
	right: 20px
	+size(20px)
	content: ''
	transform: translate3d(0, -50%, 0)
	transform-origin: 50% 0 0
	animation: 500ms linear 0s normal forwards infinite running spin
	border-width: 3px
	border-style: solid
	border-color: #aaa #ddd #ddd
	border-radius: 50%

@keyframes spin
	0%
		transform: rotate(0deg) translate3d(0, -50%, 0)

	100%
		transform: rotate(360deg) translate3d(0, -50%, 0)

.selectr-container.open.inverted .selectr-selected
	border-color: transparent #999 #999
	border-radius: 0 0 3px 3px

.selectr-container.inverted .selectr-options-container
	border-width: 1px 1px 0
	border-color: #999 #999 transparent
	border-radius: 3px 3px 0 0
	background-color: #fff

.selectr-container.inverted .selectr-options-container
	top: auto
	bottom: calc(100% - 1px)

.selectr-container::placeholder
	color: #6c7a86
	opacity: 1
