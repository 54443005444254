.slider-item
    &__container
        display: flex
        flex-direction: row
        height: 100%
        overflow: hidden
        +max-screen($breakpoint-sm)
            flex-direction: column


    &__content
        display: block
        flex-direction: column
        padding: 22px 50px
        position: relative
        +max-screen($breakpoint-md)
            padding: 15px 20px
        +max-screen($breakpoint-sm)
            height: 60%


    &__button
        margin-bottom: 37px
        position: absolute
        bottom: 0
        +max-screen($breakpoint-sm)
            display: none

        &-mobile
            display: none
            +max-screen($breakpoint-sm)
                display: initial
                margin: 20px 0 0 10px


    &__image
        flex-grow: 1
        min-width: 350px
        height: 100%
        +max-screen($breakpoint-md)
            min-width: 250px
        +max-screen($breakpoint-sm)
            height: 40%

        &-first
            min-width: 350px
            +max-screen($breakpoint-sm)
                position: relative
                background-color: $violet
                margin-top: 25px


    &__icon
        position: absolute
        top: -1px
        right: -100px
        overflow: hidden
        +max-screen($breakpoint-md)
            width: 400px
            height: 100%
            transform: scale(2.1)
            right: 0
        +max-screen($breakpoint-sm)
            width: 400px
            height: 100%
            transform: scale(2.1)
            right: 0

        &-vave
            position: absolute
            width: 100%
            display: none
            left: 0
            right: 0
            bottom: -2px
            z-index: 1
            +max-screen($breakpoint-sm)
                display: initial


        &-circle
            position: absolute
            width: 70px
            top: -107px
            right: -3px
            display: none
            z-index: 1
            +max-screen($breakpoint-sm)
                display: initial


        &-type2,
        &-type3,
        &-type4
            top: 0
            right: -119px
            +max-screen($breakpoint-md)
                top: 6px
                right: 80px
                transform: scale(1.5)

        &-type1
            top: -1px
            right: 0
            +max-screen($breakpoint-md)
                top: 6px
                right: 80px
                transform: scale(1.5)
            +max-screen($breakpoint-sm)
                display: none

            &-mobile
                display: none
                width: 100px
                height: 100px
                position: absolute
                top: auto
                bottom: 50px
                right: 50px
                +max-screen($breakpoint-sm)
                    display: initial

        &-type2
            +max-screen($breakpoint-md)
                top: 6px
                right: -151px
                transform: scale(1.2)
            +max-screen($breakpoint-sm)
                display: none

            &-mobile
                    display: none
                    width: 130px
                    height: 130px
                    position: absolute
                    top: auto
                    bottom: 30px
                    right: 50px
                    z-index: 2
                    +max-screen($breakpoint-sm)
                        display: initial

        &-type3
            +max-screen($breakpoint-md)
                top: 6px
                right: -151px
                transform: scale(1.2)
            +max-screen($breakpoint-sm)
                display: none

            &-mobile
                display: none
                width: 65px
                height: 65px
                position: absolute
                top: auto
                bottom: 25px
                right: 60px
                z-index: 2
                +max-screen($breakpoint-sm)
                    display: initial

        &-type4
            top: -1px
            +max-screen($breakpoint-md)
                top: 6px
                right: 45px
                transform: scale(2.2)
            +max-screen($breakpoint-sm)
                display: none

            &-mobile
                display: none
                width: 300px
                height: 115px
                position: absolute
                top: auto
                bottom: 25px
                right: 20px
                z-index: 2
                +max-screen($breakpoint-sm)
                    display: initial


    &__dollar
        position: absolute
        fill: $green
        top: 10%
        left: 50%
        z-index: -1
        opacity: 0.5


    &__dollar1
        position: absolute
        fill: $light-gray-bg-3
        top: 10%
        left: 65%
        width: 230px
        height: auto
        z-index: -1


    &__labels
        margin-bottom: 29px

    &__title
        margin-bottom: 20px
        +typography(title, large)
        color: $deep-cove
        +max-screen($breakpoint-md)
            +typography(title, medium)
        +max-screen($breakpoint-xs)
            +typography(title, xxsmall)


    &__subtitle
        margin-bottom: 20px
        +typography(title, xxsmall)
        color: $green
        +max-screen($breakpoint-xs)
            +typography(text, large)


    &__link
        text-decoration: none
