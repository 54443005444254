.about-page
  &__title
    +typography(title, large)
    color: $deep-cove
    margin-bottom: 10px

  &__title-description
    +typography(title, xsmall)
    color: $deep-cove
    margin-bottom: 100px

  &__subtitle
    +typography(title, medium)
    margin: 70px 0


  &__note
    +typography(title, xsmall)
    padding: 2px 0 2px 24px
    border-left: 4px solid $violet

    &-right
      width: 60%
      margin-left: auto
      +max-screen($breakpoint-sm)
        width: 100%


  &__connect
    margin-top: 30px
    padding-left: 24px

    .button._success
      color:  $white

  &__possibilities
    display: flex
    flex-direction: row
    height: 470px
    overflow: hidden
    padding: 30px
    background-color: $deep-cove
    border-radius: 20px
    +max-screen($breakpoint-md)
      flex-direction: column
      height: auto
      align-items: center


  &__lk
    height: 300px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    margin-top: 100px

    &:before
      position: absolute
      z-index: -1
      top: 0
      left: 50%
      content: ""
      margin-left: -50vw
      width: 100vw
      height: 100%
      background-color: $deep-cove


  &__bcs
    margin-top: 80px
    padding: 60px 0 20px
    border-top: 1px solid rgba(149,152,167,0.3)

  &__abuse
    padding-bottom: 60px

  &__abuse-text
    +typography(title, xxsmall)


  &__section-wrapper
      position: relative
      padding-top: 100px

      &:before
        position: absolute
        z-index: -1
        top: 0
        left: 50%
        content: ""
        margin-left: -50vw
        width: 100vw
        height: 100%
        background-color: #F8F7F7


.possibilities
  color: $white
  &__visualization
    position: relative
    +max-screen($breakpoint-xs)
      height: 0
      overflow: hidden

  &__title
    +typography(title, small)
    margin-bottom: 37px

  &__description
    align-self: center
    padding-left: 60px
    +typography(text, xlarge)
    font-weight: normal
    +max-screen($breakpoint-md)
      margin-top: 50px
    +max-screen($breakpoint-xs)
      padding-left: 0

  &__description-list
    padding-left: 20px

  &__description-list-item
    list-style: initial
    line-height: 40px


.connections
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 70px
  +max-screen($breakpoint-xs)
    flex-direction: column

  &__item-title
    +typography(title, small)
    margin-bottom: 10px

  &__item-text
    line-height: 25px

  &__item-description
    padding-left: 60px
    +max-screen($breakpoint-xs)
      margin-top: 20px

  &__item-image
    width: 179px


.bcs
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between
  +min-screen($breakpoint-lg)
    width: 80%

  &__item
    width: 48%
    margin-bottom: 24px
    +max-screen($breakpoint-sm)
      width: 100%

    &-value
     +typography(title, large)
     margin-bottom: 16px


.lk
  +typography(title, small)
  color: $white

  &__images
    position: absolute
    z-index: -1
    top: 0
    left: 50%
    content: ""
    margin-left: -50vw
    width: 100vw
    height: 100%

  &__images-wrapper
    position: relative

  &__image-left
    position: absolute
    left: 0
    z-index: -1

  &__image-right
    position: absolute
    right: 0
    z-index: -1

  &__title
    margin-bottom: 32px

  &__button-text
    color: $white


.ellipse
  position: absolute
  z-index: 1
  bottom: -37px
  right: -33px
