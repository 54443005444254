.howto
    $parent-selector: &
    $breakpoint-sm: 960px

    color: $east-bay

    &__list
        counter-reset: item
        +min-screen($breakpoint-sm)
            display: flex
            margin: 0 -15px

    &__item
        position: relative
        padding: 0 20px 0 80px
        display: flex
        align-items: center
        justify-content: flex-start
        height: 123px
        background-color: #fff
        border-radius: $border-radius
        color: $east-bay
        text-decoration: none
        overflow: hidden
        +min-screen($breakpoint-xs)
            padding: 0 60px 0 90px
        +min-screen($breakpoint-sm)
            padding: 0 60px
            margin: 0 15px
            width: calc(33.33333% - 30px)
            height: 250px
            justify-content: center

        &::before
            position: absolute
            left: 20px
            bottom: 25px
            counter-increment: item
            content: counter(item) '.'
            font-size: 72px
            font-weight: 700
            line-height: 1
            color: $light-violet
            +min-screen($breakpoint-sm)
                bottom: -20px
                font-size: 280px

        &:not(:last-child)
            +max-screen($breakpoint-sm - 1)
                margin: 0 0 30px 0

    &__title
        position: relative
        +typography(text, xlarge)
        +min-screen($breakpoint-xs)
            +typography(title, xsmall)

    &.about

    #{$parent-selector}
        &__item
            box-shadow: 0 10px 40px rgba(67, 73, 123, 0.1)
