.page-footer
	$breakpoint-sm: 992px
	$breakpoint-md: 1170px

	padding: 50px 0 60px

	&__container
		@extend %container

	&__main
		margin-bottom: 30px
		+min-screen(768px)
			margin-bottom: 65px
			display: flex
			justify-content: space-between

	&__main-nav
		+max-screen(768px)
			margin: 30px 0 40px

	&__logo
		margin-right: 40px
		+min-screen($breakpoint-md)
			margin-right: 127px

	&__contact
		+max-screen($breakpoint-sm - 1)
			width: 100%
		+min-screen($breakpoint-sm)
			text-align: right

	&__text
		font-size: 10px
		line-height: (12 / 10)
		color: $light-gray
		&:not(:last-child)
			margin-bottom: 12px

	&__link
		color: $light-gray
		&:visited
			color: $light-gray

	&__projects	
		margin: 30px 20px

		&-title
			font-weight: 600
			margin-bottom: 15px

		&-item
			display: flex
			align-items: center
			text-decoration: none
			text-wrap: nowrap
			font-weight: 500
			color: $primaryDark
			margin-bottom: 15px

		&-item-icon
			font-size: 16px
			margin-left: 8px
