.author-news
	&__item
		&:not(:last-child)
			margin: 0 0 30px 0

	+min-screen($breakpoint-sm)
		&__list
			display: flex
			flex-wrap: wrap
			margin: 0 -15px

		&__item
			padding: 0 15px
			width: 33.33333%
