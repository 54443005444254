.star-rating
    display: inline-flex
    flex-direction: row-reverse
    justify-content: center
    align-items: baseline
    +typography(title, small)

    &__container
        display: flex
        align-items: center

    &__item
        opacity: 0.3
        margin-left: 5px

        &-active
            fill: $red
            opacity: 1

        &-small
            width: 16px
            height: 16px

        &-medium
            width: 20px
            height: 20px

        &-large
            width: 26px
            height: 28px
