.breadcrumbs
	display: none
	+min-screen($breakpoint-xs)
		margin-bottom: 27px
		display: block


	&__list
		+min-screen($breakpoint-xs)
			display: flex
			align-items: center


	&__item
		+min-screen($breakpoint-xs)
			position: relative
			&:not(:last-child)
				padding-right: 25px
				&::after
					position: absolute
					top: calc(50% - 5px)
					right: 10px
					content: ''
					+size(7px, 11px)
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxMGw0LjUtNC41TDEgMSIgc3Ryb2tlPSIjOTU5OEE3Ii8+PC9zdmc+)


	&__link
		+min-screen($breakpoint-xs)
			display: block
			+typography(text, xsmall)
			text-decoration: none
			color: $light-gray
			&._home
				+hide-text
				+size(16px)
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjMzMyAxMy4zMzNhLjY2Ny42NjcgMCAwIDEtLjY2Ni42NjdIMy4zMzNhLjY2Ni42NjYgMCAwIDEtLjY2Ni0uNjY3di02aC0yTDcuNTUgMS4wNzVhLjY2Ny42NjcgMCAwIDEgLjg5OCAwbDYuODg0IDYuMjU4aC0ydjZ6IiBmaWxsPSIjOTU5OEE3Ii8+PC9zdmc+)


	&__pointer
		cursor: pointer


	&__item._active &__link
		+min-screen($breakpoint-xs)
			color: $deep-cove
			pointer-events: none
