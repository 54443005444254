.button
    position: relative
    display: inline-block
    vertical-align: middle
    text-align: center
    white-space: nowrap
    text-overflow: ellipsis
    text-decoration: none
    overflow: hidden
    border-radius: $border-radius
    user-select: none
    cursor: pointer

    &._primary,
    &._success
        &::before
            position: absolute
            top: 0
            left: 0
            content: ''
            display: block
            +size(100%, 400%)
            border-radius: 0 100% 0 0
            transition: transform .75s
            transform: translateX(-200%) scaleX(3)
            transform-origin: 0 50%

        &:hover::before
            transform: translateX(0) scale(3)

    &._primary
        color: #fff
        background-color: $violet

        &::before
            background-color: $east-bay

    &._success
        color: $deep-cove
        background-color: $green

        &::before
            background-color: $east-bay

        &:hover
            color: #fff

    &._white
        color: $deep-cove
        background-color: $white

    &._outline
        color: $light-gray
        border: 1px solid $gray

    &._disabled,
    &[disabled]
        pointer-events: none
        color: $light-gray
        background-color: $gray

    &._small
        padding: 12px 20px
        font-size: 14px
        font-weight: 600

    &._medium
        font-size: 16px
        font-weight: 700
        padding: 14px 24px

    &._large
        padding: 18px 38px
        font-size: 16px
        font-weight: 700

    &._wide
        width: 100%
        height: 100%

    &._small
        +typography(text, medium)
        padding: 10px 24px 11px 24px

    &__text
        position: relative
        display: block
        overflow: hidden
        text-overflow: ellipsis

    &._primaryNew
        border-radius: 12px
        color: $white
        background: linear-gradient(0deg, rgba(34, 153, 255, 0.6), rgba(34, 153, 255, 0.6)), linear-gradient(51.54deg, #254EAF 5.82%, #56B2EC 50.12%, #4A9EDF 95.34%)

.button-hide
    opacity: 0
