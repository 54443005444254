.subscribe-to-analytics
    display: flex
    justify-content: space-between
    align-items: center
    background-color: $deep-cove
    padding: 40px 30px
    border-radius: 8px
    +max-screen($breakpoint-md)
        flex-direction: column

    &__description
        text-align: center
        color: $white
        +max-screen($breakpoint-md)
            margin-bottom: 20px

    &__description-title
        margin-bottom: 15px
        +typography(title, small)

    &__description-sub-title
        +typography(text, large)
        font-weight: normal

    &__actions
        display: flex

    &__button
        +max-screen($breakpoint-md)
            width: 100%
