.label
    padding: 0 10px
    display: inline-block
    font-size: 12px
    font-weight: 800
    line-height: 20px
    letter-spacing: .04em
    text-transform: uppercase
    border-radius: $border-radius
    white-space: nowrap
    margin: 2px

    &._primary
        color: $blue
        background-color: $light-blue

    &._secondary
        color: $green
        background-color: $light-green

    &._transparent
        color: #fff
        background-color: rgba(#fff, .2)
