.articles
    $parent-selector: &
    padding: 0 0 40px
    color: $deep-cove
    +min-screen($breakpoint-xs)
        padding: 0 0 90px

    &__header
        margin-bottom: 21px
        display: flex
        flex-wrap: wrap
        align-items: center
        +min-screen($breakpoint-xs)
            align-items: flex-end

    &__title
        +typography(title, xsmall)
        +min-screen($breakpoint-xs)
            +typography(title, large)

    &__caption
        margin-top: 10px
        width: 100%
        +typography(text, xsmall)
        color: $light-gray
        +min-screen($breakpoint-sm)
            margin-top: 20px

    &__subscribe
        width: 100%
        margin: 30px 0 20px

    &__categories
        display: flex
        align-items: baseline
        width: 100%
        margin-top: 20px
        color: $east-bay
        +max-screen($breakpoint-xs)
            justify-content: space-between

        &-label
            font-weight: bold

        &-list
            display: flex
            flex-wrap: wrap
            margin-left: 10px
            +max-screen($breakpoint-xs)
                display: initial

        &-item
            margin: 10px 10px 0
            cursor: pointer
            +max-screen($breakpoint-xs)
                text-align: right

            &-active
                text-decoration: underline
