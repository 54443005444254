$sucess: #17BF7E
$fail: #EF5D5D

.card
    $parent-selector: &
    $breakpoint-md: 850px

    position: relative
    overflow: hidden
    padding: 30px
    height: 380px
    color: $deep-cove
    text-decoration: none
    box-shadow: 0 20px 90px rgba($east-bay, .2)

    &:after
        content: ''
        position: absolute
        left: 0
        right: 0
        bottom: 0
        top: 0
        z-index: 1

    &:hover
        #{$parent-selector}
            &__link
                background-color: $green
                transition: .2s

    +max-screen($breakpoint-sm - 1)
        margin: 0 -20px
        display: flex
        flex-direction: column
    +min-screen($breakpoint-sm)
        height: 255px
        border-radius: 20px
    +min-screen($breakpoint-md)
        display: flex
        flex-wrap: wrap
        height: 380px
    +min-screen($breakpoint-lg)
        flex-direction: column
    +between-screen($breakpoint-md, $breakpoint-lg - 1)
        height: 173px

    &._dark,
    &._violet
        color: #fff

        &::before
            position: absolute
            z-index: -1
            top: 0
            left: 0
            +size(100%)
            content: ''

    &._dark::before
        background-color: $deep-cove

    &._violet::before
        background-color: $violet

    &__header,
    &__footer
        z-index: 1
        display: flex
        align-items: center
        +min-screen($breakpoint-lg)
            justify-content: space-between

    &__header
        +between-screen($breakpoint-md, $breakpoint-lg - 1)
            margin-bottom: 38px

    &__footer
        justify-content: space-between
        +max-screen($breakpoint-sm - 1)
            margin-top: auto
        +between-screen($breakpoint-md, $breakpoint-lg - 1)
            justify-content: center
            flex-basis: 34%

    &__content
        +max-screen($breakpoint-md - 1)
            flex-grow: 1
            display: flex
            flex-direction: column
            padding-bottom: 20px
        +min-screen($breakpoint-md)
            position: relative
            z-index: 1
            flex-basis: 67%
        +min-screen($breakpoint-lg)
            display: flex
            flex-direction: column
            flex-basis: 100%
            flex: 1 0 auto
            max-width: 100%
        +between-screen($breakpoint-sm, $breakpoint-md - 1)
        // margin-bottom: 30px
        +between-screen($breakpoint-md, $breakpoint-lg - 1)
            align-items: center
            position: static
            width: 30%
            flex-basis: 30%

    &__chart
        border-bottom: 1px solid rgba(227, 227, 227, .2)
        border-left: 1px solid rgba(227, 227, 227, .2)

        &-content
            width: 200px

    &__labels-wrap
        display: flex
        align-items: center
        overflow: hidden

        &:not(:last-child)
            margin-right: 30px

    &__labels
        display: flex
        flex-direction: row
        overflow: hidden
        +min-screen($breakpoint-lg)
            flex-direction: column
        +max-screen($breakpoint-xs)
            flex-direction: column

    &__label
        &._hidden
            position: absolute
            opacity: 0

        &:not(:last-child)
            margin-right: 8px

    &__profile
        margin-bottom: 10px

    &__title
        width: 100%
        +typography(title, small)
        line-height: initial
        overflow: hidden
        +between-screen($breakpoint-md, $breakpoint-lg - 1)
            padding-right: 30px
            flex-grow: 1
        +min-screen($breakpoint-lg)

    &__info
        position: relative
        flex: none
        margin: auto 0
        +between-screen($breakpoint-sm, $breakpoint-md - 1)
            position: absolute
            z-index: 1
            left: 0
            right: 0
            bottom: 30px
            margin: 0 110px 0 230px
            display: flex
            justify-content: center
        +between-screen($breakpoint-md, $breakpoint-lg - 1)
            position: absolute
            right: 30px
            bottom: 30px
            width: 33%

    &__title-info
        position: relative

        &__description
            display: flex
            justify-content: space-between
            align-items: baseline

    &__info-inner
        position: relative
        display: flex
        align-items: center

        &._v2
            flex-direction: column-reverse
            align-items: flex-start

            #{$parent-selector}
                &__caption
                    margin-bottom: 10px
                    max-width: initial

    &__info-wrap
        display: flex
        align-items: flex-end
        justify-content: space-between

    &__value
        padding: 0 13px
        margin-right: 22px
        +typography(title, small)
        line-height: 38px
        color: #fff
        background-color: $green
        border-radius: $border-radius

        &.fall
            background-color: $red

    &__caption
        display: inline-block
        max-width: 96px
        +typography(text, xsmall)

        &._dark
            position: absolute
            left: 0
            bottom: 100%
            color: $deep-cove

        &._light
            color: $light-gray

        &:not(:last-child)
            margin-bottom: 10px

    &._dark &__caption,
    &._violet &__caption
        color: #fff

    &__link
        display: flex
        align-items: center
        justify-content: center
        +size(50px)
        border-radius: 100%
        text-decoration: none
        fill: #fff
        flex-shrink: 0
        background-color: $deep-cove
        transition: background $default-transition-duration $default-transition-function, fill $default-transition-duration $default-transition-function
        +max-screen($breakpoint-lg - 1)
            margin-left: 30px
        +between-screen($breakpoint-md, $breakpoint-lg - 1)
            position: absolute
            right: 30px
            top: 30px

    &._dark
        #{$parent-selector}
            &__link
                background-color: $violet

        &:hover
            #{$parent-selector}
                &__link
                    fill: $deep-cove
                    background-color: #fff

    &._violet
        #{$parent-selector}
            &__link
                fill: $violet
                background-color: #fff

        &:hover
            #{$parent-selector}
                &__link
                    fill: #fff
                    background-color: $deep-cove

    &__waves
        position: absolute
        z-index: -1
        left: 0
        bottom: 0
        width: 100%
        fill: $green
        object-fit: cover
        pointer-events: none

    &._dark &__waves,
    &._violet &__waves
        fill: #fff

    &__wave
        transition: transform $default-transition-duration $default-transition-function

        &._front
            opacity: .2

        &._back
            opacity: .1

    &:hover &__wave._front
        transform: scaleX(1.2)

    &:hover &__wave._back
        transform: scaleX(1.2) translateX(-50px)

    +min-screen($breakpoint-lg)
        &__info-inner
            &._v2
                flex-direction: column

                #{$parent-selector}
                    &__caption
                        margin: 10px 0 -3px 0

                    &__value
                        margin-right: 35px

.stable-card
    $parent-selector: &
    $breakpoint-md: 850px
    position: relative
    overflow: hidden
    padding: 30px
    height: 380px
    color: $deep-cove
    text-decoration: none
    box-shadow: 0 20px 90px rgba($east-bay, .2)
    flex-direction: column
    display: flex
    flex-wrap: wrap
    border-radius: 20px

    &:after
        content: ''
        position: absolute
        left: 0
        right: 0
        bottom: 0
        top: 0
        z-index: 1

    &:hover
        #{$parent-selector}
            &__link
                background-color: $green
                transition: .2s

    &:hover &__wave._front
        transform: scaleX(1.2)

    &:hover &__wave._back
        transform: scaleX(1.2) translateX(-50px)

    &._dark,
    &._violet
        color: #fff

        &::before
            position: absolute
            z-index: -1
            top: 0
            left: 0
            +size(100%)
            content: ''

    &._dark::before
        background-color: $deep-cove

    &._violet::before
        background-color: $violet

    &._dark &__caption,
    &._violet &__caption
        color: #fff

    &._dark
        #{$parent-selector}
            &__link
                background-color: $violet

        &:hover
            #{$parent-selector}
                &__link
                    fill: $deep-cove
                    background-color: #fff

    &._violet
        #{$parent-selector}
            &__link
                fill: $violet
                background-color: #fff

        &:hover
            #{$parent-selector}
                &__link
                    fill: #fff
                    background-color: $deep-cove

    &._dark &__waves,
    &._violet &__waves
        fill: #fff

    &__header
        margin-bottom: 47px
        width: 100%
        justify-content: space-between
        z-index: 1
        display: flex
        align-items: center

    &__footer
        z-index: 1
        display: flex
        align-items: center
        justify-content: space-between

    &__content
        display: flex
        flex-direction: column
        flex: 1 0 auto
        position: relative
        z-index: 1
        +max-screen($breakpoint-xs)
            width: 100%

    &__info
        position: relative
        flex: none

    &__title-info
        position: relative

    &__profile
        margin-bottom: 10px

    &__title
        +typography(title, small)
        height: (28px *.92857 * 2)
        overflow: hidden

    &__info-wrap
        display: flex
        align-items: flex-end
        justify-content: space-between

    &__info-inner
        position: relative
        display: flex
        align-items: center

        &._v2
            flex-direction: column
            align-items: flex-start

            #{$parent-selector}
                &__caption
                    margin: 10px 0 10px 0

                    max-width: initial

                &__value
                    margin-right: 35px

    &__chart
        border-bottom: 1px solid rgba(227, 227, 227, .2)
        border-left: 1px solid rgba(227, 227, 227, .2)

        &-content
            width: 160px
            margin-left: 5px

    &__value
        padding: 0 13px
        margin-right: 22px
        +typography(title, small)
        line-height: 38px
        color: #fff
        background-color: $green
        border-radius: $border-radius

        &.fall
            background-color: $red

    &__caption
        display: inline-block
        max-width: 96px
        +typography(text, xsmall)

        &._dark
            position: absolute
            left: 0
            bottom: 100%
            color: $deep-cove

        &._light
            color: $light-gray

        &:not(:last-child)
            margin-bottom: 10px

    &__labels-wrap
        display: flex
        align-items: center
        overflow: hidden

        &:not(:last-child)
            margin-right: 30px

    &__labels-overflow
        display: none

    &__labels
        display: flex
        overflow: hidden

    &__label
        &._hidden
            position: absolute
            opacity: 0

        &:not(:last-child)
            margin-right: 8px

    &__counter
        flex: none

        &:empty
            display: none

    &__link
        display: flex
        align-items: center
        justify-content: center
        +size(50px)
        border-radius: 100%
        text-decoration: none
        fill: #fff
        flex-shrink: 0
        background-color: $deep-cove
        transition: background $default-transition-duration $default-transition-function, fill $default-transition-duration $default-transition-function
        margin-left: 30px

    &__waves
        position: absolute
        z-index: -1
        left: 0
        bottom: 0
        width: 100%
        fill: $green
        object-fit: cover
        pointer-events: none

    &__wave
        transition: transform $default-transition-duration $default-transition-function

        &._front
            opacity: .2

        &._back
            opacity: .1

.card-new
    display: block
    height: 372px
    width: 100%
    border-radius: 24px
    overflow: hidden
    text-decoration: none
    background: linear-gradient(0deg, rgba(34, 153, 255, 0.6), rgba(34, 153, 255, 0.6)), linear-gradient(50.91deg, #254EAF 9.44%, #4EB0EE 54.25%, #4A9EDF 100%)

    +min-screen($breakpoint-xl)
        width: 420px

    &.highlighted
        padding: 3px

.card-new-container
    display: flex
    flex-direction: column
    height: 100%
    color: $white
    background-size: 100%
    background-color: rgba($primaryDark, 1)
    border-radius: 24px
    transition: all 0.5s ease-in-out

    &:hover
        background-size: 103%

    &.one
        background-image: url('/images/card/background1.svg')
    &.two
        background-image: url('/images/card/background2.svg')
    &.three
        background-image: url('/images/card/background3.svg')
    &.four
        background-image: url('/images/card/background4.svg')

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        margin: 24px 24px 0 24px

        &-left
            display: flex
            align-items: center
            font-size: 16px
            font-weight: 700
            padding: 12px 14px
            border: 1px solid $white
            border-radius: 12px

            &-currency
                font-size: 11px
                padding: 1px 4px
                border-radius: 4px
                margin-left: 6px
                color: $primaryDark
                background-color: $white

        &-right
            display: flex
            align-items: center
            color: rgba(255, 255, 255, 0.6)

            &-risk
                width: 60px
                height: 24px
                display: flex
                align-items: center
                padding: 4px 6px
                border-radius: 12px
                margin-left: 8px
                backdrop-filter: blur(7.5px)
                background: rgba(255, 255, 255, 0.2)

                &-wrapper
                    height: 14px
                    margin: 0 3px

    &__content
        margin: auto 24px 36px

        &-name
            +typography(title, small)
            line-height: 1.2

        &-profit
            width: fit-content
            font-size: 14px
            font-weight: 500
            margin-top: 12px
            padding: 8px 12px
            border-radius: 16px
            background: rgba(255, 255, 255, 0.2)

            &.success
                color: $sucess

            &.fail
                color: $fail

    &__marker
        display: flex
        align-items: center
        position: relative

        &-separator
            flex-grow: 1
            height: 0.5px
            background-color: #555555

        &-figure
            display: flex
            align-items: center
            position: absolute
            z-index: 1
            right: 0

    &__footer
        display: flex
        align-items: center
        justify-content: space-between
        padding: 24px
        pointer-events: none
        backdrop-filter: blur(7.5px)
        border-bottom-left-radius: 24px
        border-bottom-right-radius: 24px
        background: linear-gradient(180deg, rgba($primaryDark,0.1) 0%, rgba($primaryDark,1) 100%)

        &.space
            padding-bottom: 21px

        &-icon
            width: 56px
            height: 56px
            border-radius: 12px
            background-size: contain
            background-position: center

        &-right-chart
            width: 100px

            +min-screen($breakpoint-xs)
                width: 124px

        &-left
            display: flex
            align-items: center

            &-wrapper
                height: 100%
                display: flex
                flex-direction: column
                margin-left: 8px

            &-value,
            &-description
                color: rgba($white, 0.6)

            &-description
                margin-bottom: 6px

            &-percent
                font-size: 28px
                font-weight: 800
                color: $white

.card-new-skeleton
    border-radius: 12px
    background-color: rgba($backgroundDark, 0.03)
