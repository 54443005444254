.strategy-detail
    $border-color: $light-violet

    &__title
        margin-bottom: 30px
        +typography(title, small)
        +min-screen($breakpoint-xs)
            +typography(title, large)

    &__title-wrapper
        display: flex
        align-items: center

    &__row
        position: relative
        +max-screen($breakpoint-sm - 1)
            padding-top: 0
        +max-screen($breakpoint-xs - 1)
            padding-top: 0
        +min-screen($breakpoint-sm)
            display: flex
            margin: 0 -15px

    &__content
        +min-screen($breakpoint-sm)
            display: flex
            flex-direction: row
            width: 100%
            padding: 0 15px
            &-section
                width: 50%

    &__sidebar
        +max-screen($breakpoint-sm - 1)
            padding-top: 30px
            margin-top: 30px
        +min-screen($breakpoint-sm)
            padding: 0 15px 0 50px
            width: 50%

    &__hr
        margin: 30px 0 39px
        border: none
        border-top: 1px solid $border-color

    &__videos
        margin-top: 35px
        margin-bottom: 0 !important

    &__video
        margin-top: 20px

    &__author
        margin-bottom: 25px

    &__subtitle
        margin-top: 20px
        margin-bottom: 27px
        +typography(title, xxsmall)
        +min-screen($breakpoint-xs)
            +typography(title, small)

    &__text
        +typography(text, xlarge)
        font-weight: 400
        color: $east-bay
        margin-bottom: 30px

        p
            margin-bottom: 15px
            text-align: justify

        ul, ol
            margin: 10px 5px

        ul > li
            list-style: disc
            margin-left: 20px

        ol > li
            list-style: decimal
            margin-left: 20px

        &._bold
            font-weight: 700

    &__desclimer
        font-size: 11px
        font-weight: 400
        color: $east-bay
        margin-bottom: 30px

    &__sales-points
        margin-top: 25px

    &__controls
        display: flex
        flex-wrap: wrap
        align-items: center
        padding: 30px 0
        margin-bottom: 30px
        border-bottom: 1px solid $border-color
        border-top: 1px solid $border-color
        +max-screen($breakpoint-sm - 1)
            display: none
            position: absolute
            top: 0
            left: 0
            width: 100%

    &__info
        display: flex
        +max-screen($breakpoint-xs - 1)
            margin-top: 25px
            margin-left: 0
            order: 2
            width: 100%
        +between-screen($breakpoint-sm, $breakpoint-md - 1)
            margin-top: 25px
            margin-left: 0
            order: 2
            width: 100%

    &__share
        margin-left: auto
        padding: 8px
        +size(40px)
        fill: $deep-cove
        background: #fff
        box-shadow: $shadow
        border-radius: 12px

    &__labels
        margin-top: 30px
        margin-bottom: -7px
        padding-top: 39px
        display: flex
        flex-wrap: wrap
        border-top: 1px solid $border-color
        +min-screen($breakpoint-sm)
            width: calc(50% - 15px)

    &__label
        margin-bottom: 7px

        &:not(:last-child)
            margin-right: 7px

    &__connect
        text-align: left

    &__description
        +typography(caption, medium)
        color: $light-gray

    &__tooltip
        line-height: 16px

    &__tab
        display: none !important
        +max-screen($breakpoint-md)
            display: initial !important

    &__profit
        +max-screen($breakpoint-md)
            display: none

.with-loading
    position: relative
    &-loading
        &:before
            content: ''
            position: absolute
            top: -10px
            left: -10px
            right: -10px
            bottom: -10px
            z-index: 1
            background-color: rgba(235,233,234,0.3)

.strategy-params
    display: flex
    flex-direction: column
    +typography(text, xlarge)
    font-weight: initial

    &__all
        margin-top: 30px
        +typography(text, xlarge)

    &__row
        display: flex
        flex-direction: row
        justify-content: space-between
        padding: 22px 0
        border-bottom: 1px solid $gray

        &-name
            color: $light-gray

        &-addition
            +typography(text, small)
            font-weight: 600

        &-description
            text-align: right

    &__link
        color: $deep-cove
        text-decoration: underline

.strategy-profit
    display: flex
    padding-right: 30px
    &__value
        +typography(title, large)
        color: $green

        &.fall
            color: $red

    &__caption
        margin-left: 16px
        position: relative
        +typography(text, xsmall)
        color: $light-gray

        &::before
            content: '*'
            position: absolute
            top: -5px
            right: -10px
            +typography(text, large)
            color: $red

.strategy-statistic
    +typography(text, xsmall)
    color: $light-gray
    border-left: 1px solid rgba(149,152,167,0.6)
    padding-left: 15px

.recent-deals
    &_horizontal
        display: flex
        flex-direction: row
        align-items: baseline
        justify-content: space-between
        +max-screen($breakpoint-sm)
            display: block

.recent-deal
    padding: 30px 0

    &-wrapper
        padding: 0 15px 30px
        width: (100% / 3)
        text-decoration: none
        +max-screen($breakpoint-sm)
            width: initial

    &:first-child
        padding-top: 0

    &__header
        display: flex
        align-items: center
        margin-bottom: 16px

        svg
            min-width: 30px
            min-height: 30px

    &__title
        text-transform: uppercase
        margin-left: 10px
        +typography(text, xlarge)
        font-weight: 700

        &-up
            color: $green
        &-fail
            color: $red

    &__sub-title
        color: $deep-cove
        +typography(title, xsmall)
    &__description
        margin: 10px 0
        +typography(text, small)
        color: $east-bay

    &__description-more
        font-weight: 600
        cursor: pointer

    &__date
        +typography(text, xsmall)
        color: $light-gray
