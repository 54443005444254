.modal-strategy
    $parent-selector: &

    &__info-title
        margin-bottom: 12px
        color: $light-gray
        +typography(text, small)

    &__info-description
        color: $deep-cove
        +typography(text, medium)

    &__info-row
        margin-bottom: 24px

        .react-select__menu-list
            max-height: 130px

        .react-select__option
            padding: 16px 20px

    &__link
        color: $green
        text-decoration: none

    &__iir
        margin-bottom: 10px

    &__button
        white-space: pre-wrap
        line-height: 1.3
        padding: 16px 38px

    &__buttons-col
        margin-bottom: 20px

        &:last-child
            margin-bottom: 0

    &__buttons-description
        margin-top: 10px
        color: $light-gray
        +typography(text, small)

    &__buttons-price
        margin-top: 10px
        color: $light-gray
        +typography(text, xsmall)

    &__buttons-promotion
        color: $east-bay

    &__account
        +typography(text, xsmall)
        color: $red
        margin-top: 8px

    &__account-content
        margin-top: 8px
        +typography(text, xsmall)
        color: $light-gray

    &__account-row
        display: flex
        align-items: baseline

    &__account-title
        width: 130px
        margin-right: 20px

    +min-screen($breakpoint-xs)
        &__buttons-row
            display: flex
            margin-left: -10px
            margin-right: -10px
        &__buttons-col
            padding-right: 10px
            padding-left: 10px
            min-width: 50%
            margin-bottom: 0

        &__info
            background-color: $light-gray-bg-2
            border-radius: 20px
            padding: 30px 20px
            margin-bottom: 30px
        &__info-row
            display: flex
            align-items: baseline

            &:last-child
                margin-bottom: 0

            &._account
                align-items: flex-start

                #{$parent-selector}
                    &__info-title
                        padding-top: 12px
        &__info-title
            +typography(text, small)
            width: 33%
            margin-bottom: 0
            margin-right: 30px
            flex-shrink: 0
        &__info-description
            flex-grow: 1
    +max-screen($breakpoint-xs - 1)
        &__info-sum
            display: block


