.gauge
	position: relative
	+max-screen($breakpoint-xs - 1)
		display: flex
		align-items: center
	&__inner
		position: relative
		+max-screen($breakpoint-xs - 1)
			width: 116px
	&__needle
		position: absolute
		left: 50%
		bottom: 0
		+size(30px)
		width: 16.442%
		height: 30%
		transition: .3s ease-in-out
		transform: translateX(-50%) rotate(0deg)
		&::before
			position: absolute
			top: 5px
			left: -100%
			+size(53px, 15px)
			+size(176.667%, 50%)
			content: ''
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MiAxNSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iIzVFQ0JBMSIgZD0iTTQ0LDBjNC40LDAsOCwzLjMsOCw3LjVTNDguNCwxNSw0NCwxNUMzOS41LDE1LDAsNy44LDAsNy44UzM5LjUsMCw0NCwwTDQ0LDB6Ii8+PC9zdmc+)
			background-size: auto 100%

	&__bg
		opacity: .2
		fill: $gray
		box-shadow: inset 0 -8px 26px rgba(#43588a, .1)
		border-radius: 100% 100% 0 0

	&__progress
		+size(100%, auto)
		&._reverse
			transform: scale(-1, 1)

	&__label
		margin-top: 15px
		+typography(text, medium)
		text-align: center
		+max-screen($breakpoint-xs - 1)
			margin-left: 20px
			flex-shrink: 0
