.page
	font-family: $font-family
	display: flex
	flex-direction: column
	min-width: 320px
	overflow-x: hidden
	color: $deep-cove
	&._modal-opened
		overflow: hidden
		height: 100vh
		padding-right: var(--scrollbar-width)
	&._menu-opened
		+max-screen($breakpoint-md - 1)
			overflow: hidden

	&__content
		position: relative
		flex: 1 0 auto
		@extend %container

	&__header
		flex-shrink: 0

	&__footer
		flex-shrink: 0
