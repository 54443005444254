.logo
    margin-right: 36px
    $parent-selector: &

    &._light
        color: $white

    &._dark
        color: $primaryDark

    &._small
        +typography(title, xsmall)

    &._large
        +typography(title, xxsmall)
        +min-screen($breakpoint-xs)
            +typography(title, small)

    &__link
        color: inherit
        text-decoration: none

    &__icon
        position: relative
