.account-description
	$parent-selector: &
	&__row
		margin-bottom: 24px
		display: flex
		justify-content: space-between
		align-items: baseline
	&__info
		&._v2
			display: flex
			flex-wrap: wrap
			justify-content: space-between
		&._v3
			padding-bottom: 30px
			margin-bottom: 20px
			border-bottom: 1px solid $gray
	&__info-title
		+typography(title, xxsmall)
		margin-bottom: 10px
	&__info-caption
		+typography(text, xsmall)
		color: $light-gray
	&__info-item
		margin-bottom: 24px
		&._revers
			display: flex
			flex-direction: column
			flex-flow: column-reverse
			#{$parent-selector}
				&__info-title
					margin-bottom: 0
					margin-top: 10px
	&__period
		padding-right: 20px
		+typography(text, medium)
		color: $deep-cove
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMUw1LjUgNS41IDEgMSIgc3Ryb2tlPSIjMDUwQzQyIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=)
		background-position: center right
		background-repeat: no-repeat
	&__chart
		min-width: 730px
	&__chart-info
		margin-right: 10px
	&__chart-wrap
		padding-bottom: 20px
		margin-bottom: 40px
		overflow: auto
	&__chart-img
		max-width: 158px
		height: 158px
	&__chart-legend
		flex-grow: 1
		margin: 10px 0 0
	&__table
		margin-bottom: 40px

		th, td
			text-align: right
			&:first-child
				text-align: left
	&__close
		position: relative
		padding: 9px 20px 10px 20px
		text-align: center
		+typography(text, medium)
		color: $light-gray
		border-radius: 4px
		border: 1px solid $gray
		@extend .animated
		width: 100%
		._desktop &
			&:hover
				color: $deep-cove
				border-color: $light-gray

	&__clientcode
		float: left
		text-align: left
		max-width: 75%

		span
			font-weight: bold
			padding-left: 10px

	&__title
		+typography(title, xxsmall)
	+min-screen($breakpoint-xs)
		&__chart-legend
			margin: 0 0 0 40px
		&__info
			&._v2
				display: flex
				flex-wrap: wrap
				#{$parent-selector}
					&__info-item
						margin-bottom: 0
		&__info-item
			margin-right: 50px
		&__period
			margin-top: 5px
		&__chart
			min-width: 650px
		&__chart-wrap
			margin-bottom: 10px
		&__chart-img
			max-width: 126px
			height: 126px
			margin-top: 15px
		&__close
			width: auto
		&__table
			.table__row
				display: block
	+min-screen($breakpoint-sm)
		&__chart-img
			max-width: 250px
			height: 250px
			width: 250px
		&__table
			margin-bottom: 0
		&__chart-info
			margin-bottom: 40px
		&__info-item
			order: 2
			&._v2
				order: 1
	+min-screen($breakpoint-md)
		&__table
			th
				white-space: nowrap
			th, td
				&:first-child
					width: 45%
				&:nth-child(2)
					width: 15%
	+min-screen($breakpoint-lg)
		&__table
			th, td
				&:first-child
					width: 40%
				&:nth-child(2)
					width: 11.5%
