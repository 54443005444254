.cards
	&__list
		+min-screen($breakpoint-sm)
			margin: -30px 0 0
		+min-screen($breakpoint-lg)
			margin: 0 -15px -30px
			display: flex
			flex-wrap: wrap

	&__item
		+min-screen($breakpoint-sm)
			padding: 30px 0 0
		+min-screen($breakpoint-lg)
			padding: 0 15px 30px
			width: (100% / 3)

	&:not(._expanded) &__item
		+max-screen($breakpoint-sm - 1)
			//&:nth-child(n+4)
			//	display: none

	&._collapsed &__item
		&:nth-child(n+7)
			display: none

	&__footer
		margin-top: 35px
		text-align: center

	&._expanded &__footer
		display: none

.article-cards
	&__list
		+min-screen($breakpoint-md)
			margin: 0 -15px -30px
			display: flex
			flex-wrap: wrap

	&__item
		+min-screen($breakpoint-md)
			padding: 0 15px 30px
			width: (100% / 3)
		+max-screen($breakpoint-md)
			margin-top: 15px

.cards-new
	&__list
		display: grid
		gap: 1rem

		+min-screen($breakpoint-sm)
			grid-template-columns: repeat(2, 1fr)

		+min-screen($breakpoint-md)
			grid-template-columns: repeat(3, 1fr)

	&__wrapper
		text-align: center
		margin-top: 54px

	&__show-more
		width: 254px
