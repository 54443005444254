.pie-chart-details
    max-width: 100%
    margin: 40px 0 0
    +min-screen($breakpoint-xs)
        margin: 0 0 0 40px

        &__table
            .table__row
                display: block


    +min-screen($breakpoint-sm)
        &__table
            margin-bottom: 0


    +min-screen($breakpoint-md)
        &__table
            th
                white-space: nowrap
            th, td
                &:first-child
                    width: 45%
                &:nth-child(2)
                    width: 15%


    +min-screen($breakpoint-lg)
        &__table
            th, td
                &:first-child
                    width: 40%
                &:nth-child(2)
                    width: 11.5%


    &__table
        margin-bottom: 40px

        th, td
            text-align: right
            &:first-child
                text-align: left

    &__item
        display: flex
        justify-content: flex-start
        +max-screen($breakpoint-xs)
            justify-content: space-between

        &:not(:last-child)
            margin-bottom: 26px

    &__item-name
        margin-left: 10px

    &__color
        flex-shrink: 0
        +size(28px)
        border-radius: 8px

        &_small
            +size(18px)
            border-radius: 6px
