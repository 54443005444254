.pagination
	$parent-selector: &
	display: inline-flex
	margin-left: -8px
	margin-right: -8px
	margin-top: 12px
	&__link
		display: flex
		align-items: center
		justify-content: center
		flex-shrink: 0
		+size(38px)
		border-radius: 4px
		color: $deep-cove
		text-decoration: none
		+typography(text, medium)
		&._active
			color: #ffffff
			background-color: $violet
	&__pages
		display: flex
		flex-wrap: wrap
		background-color: $light-gray-bg
		border-radius: 25px
		padding: 0 8px
	&__next,
	&__prev
		border-radius: 100%
		background: $light-gray-bg no-repeat center / 7px 11px
	&__next
		margin-left: 7px
		background-image: url('/images/ico/chevron-right.svg')
		#{$parent-selector}
			&__icon
				margin-left: 2px
	&__prev
		background-image: url('/images/ico/chevron-left.svg')
		margin-right: 7px
		#{$parent-selector}
			&__icon
				margin-right: 2px
	+min-screen($breakpoint-xs)
		margin: 20px 0 0 0
