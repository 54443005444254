.gauge-group
	+min-screen($breakpoint-xs)
		margin-left: -16px
		margin-right: -16px
		display: flex

	&__item
		+max-screen($breakpoint-xs - 1)
			&:not(:last-child)
				margin-bottom: 30px
		+min-screen($breakpoint-xs)
			padding: 0 16px
			width: (100% / 3)
			max-width: 204px
