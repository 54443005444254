.author-text
	&__col
		margin-bottom: 17px
		&:last-child
			margin-bottom: 0
	+min-screen($breakpoint-xs)
		&__col
			margin-bottom: 30px
	+min-screen($breakpoint-md)
		display: flex
		flex-wrap: wrap
		margin: 0 -15px
		&__col
			padding: 0 15px
			width: 50%
			margin-bottom: 0
