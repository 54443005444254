.modal-attention
	&__text
		max-width: 540px
		margin: 0 auto
		text-align: center
		+typography(text, medium)
		color: $east-bay
		a
			color: $green
			text-decoration: none
	&__icon
		width: 136px
		margin: 0 auto 30px auto
	+min-screen($breakpoint-xs)
		min-height: 510px
		display: flex
		flex-direction: column
		justify-content: center
		&__icon
			margin-bottom: 40px
	+max-screen($breakpoint-xs - 1)
		&__text
			br
				display: none

	&__action
		margin-top: 20px
		text-align: center
