.chart
    &._legend
        +min-screen($breakpoint-xs)
            .chart__img
                width: 190px
            display: flex
            align-items: flex-start

    &._no-flex
        display: block

    &__periods
        display: flex
        margin: 10px 0 10px 0

    &__period
        position: relative
        +typography(text, medium)
        margin: 10px
        color: $light-gray
        cursor: pointer

        &-active
            color: $deep-cove

            &:before
                content: ""
                position: absolute
                left: 0
                bottom: -5px
                height: 1px
                width: 70%
                border-bottom: 1px solid $deep-cove

        &-disable
            opacity: 0.2
            cursor: initial

    &__img
        display: block
        max-width: 100%
        +max-screen($breakpoint-xs - 1)
            margin: 0 auto


    &._large &__img
        width: 100%
        height: auto


    &__legend
        border-spacing: 0 10px
        border-collapse: initial
        margin: 40px 0 0
        +min-screen($breakpoint-xs)
            margin: 0 0 0 40px

    &__positions
        margin-top: 10px
        font-size: 15px
        font-weight: 600
        line-height: 1.38889
        color: #43497b
    /* margin-bottom: 30px;

    &__item
        display: flex
        justify-content: flex-start
        +max-screen($breakpoint-xs)
            justify-content: space-between

        &:not(:last-child)
            margin-bottom: 26px

        &__name
            margin-left: 10px

    &__column
        +typography(text, medium)
        text-align: start
        vertical-align: middle

        &-right
            text-align: end

        & > div
            margin-right: 12px

        &  .position__tooltip
            width: 100px
            left: -100px


    &__color
        flex-shrink: 0
        +size(28px)
        border-radius: 8px

        &._small
            +size(18px)
            border-radius: 6px


    &__description
        font-size: 10px
        line-height: (12 / 10)
        color: $light-gray


.chart-legend-moc
    font-size: 18px
    font-weight: 600
    line-height: 1.38889
    color: #43497b
    margin-bottom: 20px
    margin-top: 20px

    &__text
        margin-bottom: 20px

    &__button
        text-align: center


.chart-tooltip
    display: inline-block
    font-size: 14px
    color: #9598A7
    margin-bottom: 5px

    &__point-dot
        display: inline-block
        margin-right: 6px

    &__series
        color: #282828
        font-size: 14px

    &__series-value
        display: inline-block
        margin-left: 5px
