.services
    &__title
        margin-top: 20px
        +typography(title, medium)
        color: $deep-cove
        +max-screen($breakpoint-sm)
            +typography(title, small)

    &__content
        margin-top: 25px
        margin-bottom: 10px
        +typography(text, medium)

    &__sub-title
        margin-top: 15px
        margin-bottom: 10px
        +typography(text, xlarge)
        font-weight: 700

    &__point
        margin-top: 25px
        margin-bottom: 10px
        +typography(text, medium)
