.page-experts
  display: flex
  flex-direction: row
  +max-screen($breakpoint-md)
    flex-direction: column

  &__col-left
    max-width: 70%
    width: 70%
    +max-screen($breakpoint-md)
      max-width: initial
      width: initial

  &__col-right
    margin-left: 30px
    min-width: 412px
    max-width: 30%
    +max-screen($breakpoint-md)
      margin-left: 0
    +between-screen($breakpoint-md, $breakpoint-lg - 1)
    +max-screen($breakpoint-xs)
      min-width: auto
      max-width: 100%

  &__col-block
    margin-bottom: 30px


.experts-statistics
  background: #F8F7F7
  border-radius: 20px

  &__row
    width: 212px
    margin: 0 auto
    text-align: center

    &:first-child
      padding-top: 70px

    &:last-child
      padding-bottom: 70px

  &__row-count
    margin-bottom: 10px
    +typography(title, xlarge)

  &__row-separator
    width: 68px
    height: 4px
    margin: 32px auto 40px auto
    background-color: $violet


.experts-documents
  background: #F8F7F7
  border-radius: 20px
  padding: 40px

  &__title
    margin-bottom: 40px
    +typography(title,large)


  &__group-container
      margin-bottom: 35px

  &__group
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    &:hover
        cursor: pointer


  &__group-name
      font-weight: bold

  &__group-icon
      width: 18px
      min-width: 18px
      height: 18px
      transition: all .3s
      margin-left: 16px
      transform: rotate(-45deg)

      svg
          width: 100%
          height: 100%

      &_active
          transform: rotate(0deg)


  &__group-list
      margin: 20px

  &__row-container
    margin-bottom: 22px

  &__row
    display: flex
    flex-direction: row

  &__row-icon
    fill: $deep-cove
    flex-shrink: 0


  &__row-title
      margin-left: 27px
      line-height: 130%
      color: $deep-cove

      &:hover
          text-decoration: underline
          cursor: pointer

  &__row-description
      margin: 5px 0 -16px 46px
      padding-bottom: 16px
      +typography(text,small)
      color: $dark


.experts-advantages
  &__title
    margin-bottom: 40px
    +typography(title, large)

  &__list
    margin-left: 20px
    margin-bottom: 60px
    list-style: disc
    line-height: 45px

  &__list-item
    list-style: disc
    color: $east-bay

  &__note
    +typography(title, xsmall)
    padding: 2px 0 2px 24px
    border-left: 4px solid $violet

  &__docs
      margin: 40px 0


.experts-request
  margin-top: 90px
  padding: 100px 112px
  box-shadow: 0 20px 90px rgba(67, 73, 123, 0.2)
  border-radius: 20px
  margin-bottom: 40px
  +max-screen($breakpoint-xs)
    padding: 50px 56px

  &__title
    text-align: center
    margin-bottom: 28px
    +typography(title, large)

  &__description
    text-align: center
    color: $east-bay

  &__form
    margin-top: 70px

  &__form-button-wrapper
    text-align: center
    margin-top: 30px

  &__success
    text-align: center
    +typography(title, small)

  &__success-content
    margin-top: 28px
    +typography(text, xlarge)


.form-control-group
  display: flex
  flex-direction: row
  justify-content: space-between

  &__item
    width: 48%

  +max-screen($breakpoint-sm)
      display: block

      &__item
        margin-bottom: 15px
        width: 100%


.form-control-row
  margin-bottom: 20px

  &__separator
    height: 1px
    background-color: $light-gray
    opacity: 0.25
    margin: 30px 0


.experts-questions
  margin-bottom: 90px
  position: relative
  +max-screen($breakpoint-xs)
    margin-bottom: 30px

  &__list
    display: flex
    flex-direction: row
    justify-content: space-between
    +max-screen($breakpoint-xs)
      flex-direction: column

  &__item
    max-width: 30%
    position: relative
    flex-shrink: 0
    padding: 40px 40px 70px
    margin-right: 30px
    background: $violet
    border-radius: 20px
    line-height: 30px
    +typography(title, xsmall)
    color: $white
    +max-screen($breakpoint-xs)
      max-width: initial
      margin-right: 0
      margin-bottom: 30px
      padding: 20px 20px 40px
      min-height: 150px
    +between-screen($breakpoint-md, $breakpoint-lg - 1)
      padding: 20px 20px 70px
      +typography(title, xxsmall)
      color: $white
    +between-screen($breakpoint-xs, $breakpoint-md - 1)
      padding: 10px 10px 70px
      +typography(title, xxsmall)
      color: $white

  &__item-wave
    display: none
    +max-screen($breakpoint-xs)
      display: initial

  &__waves-wrapper
    display: initial
    +max-screen($breakpoint-xs)
      display: none

  &__waves
    position: absolute
    left: 0
    bottom: 0
    fill: $white
    object-fit: cover
    pointer-events: none

  &__wave
    transition: transform $default-transition-duration $default-transition-function
    &._front
      opacity: .2
    &._back
      opacity: .1

  &:hover &__wave._front
    transform: scaleX(1.2)

  &:hover &__wave._back
    transform: scaleX(1.2) translateX(-50px)


.upload-row
  width: 100%
  margin-top: 20px
  margin-bottom: 10px

  &__header,
  &__footer
    display: flex
    flex-direction: row
    justify-content: space-between
    +typography(text,medium)

  &__footer
    color: $light-gray

  &__header-cancel
    cursor: pointer

  &__progress-bar-wrapper
    width: 100%
    margin: 10px 0

  &__progress-bar
    width: 0%
    height: 5px
    border-radius: 5px
    background-color: $green


.uploaded-row
  display: flex
  flex-direction: row
  justify-content: flex-start
  background: $white
  box-shadow: 0px 10px 50px rgba(67, 73, 123, 0.15)
  border-radius: 4px
  margin-bottom: 10px
  padding: 20px
  align-items: center

  &-deleting
    position: relative

    &:before
      content: ''
      position: absolute
      z-index: 2
      top: 0
      right: 0
      left: 0
      bottom: 0
      background-color: $gray
      opacity: 0.7

  &__file
    flex-shrink: 0
    fill: $deep-cove

  &__name
    margin: 0 10px
    max-width: 70%
    text-overflow: ellipsis
    overflow: hidden

  &__size
    +typography(text,medium)
    color: $light-gray
    margin-left: 5%

  &__delete
    margin: 0 10px 0 auto
    cursor: pointer


.form-upload
  .uppy-DragDrop-container
    background-color: #F8F7F7
    border: 1px dashed #9598A7

    &:focus
      box-shadow: none

  .uppy-DragDrop-arrow
    display: none

  .uppy-DragDrop-inner
    padding: 50px 20px

  .uppy-DragDrop-label
    +typography(text,medium)

    &:before
      content: ''
      width: 30px
      height: 30px
      background-image: url('/images/add.png')
      background-repeat: no-repeat
      background-position: center
      padding: 10px 23px 10px 10px
