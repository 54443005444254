.global-loader
    min-height: 70vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.loader
    border: 3px solid $light-gray-bg
    border-top: 3px solid $primaryBlue
    border-radius: 50%
    width: 50px
    height: 50px
    animation: spin 0.7s linear infinite

@keyframes spin
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)
