.question
  padding-left: 3px
  vertical-align: middle
  text-align: left

  &__background
    fill: #fff
    opacity: 0
  &__content
    fill: #5ECBA1

.tooltip
  text-align: left
  font-size: 13px
  padding: 10px

  &__title
    font-weight: bold

  &__list
    padding: 10px 0 10px 0

  &__content
    border-bottom: 1px solid #c0c0c0
    padding-bottom: 5px
    margin-bottom: 20px

  &__link
    color: #c0c0c0
    padding: 10px 0 0 0
    float: right
    cursor: pointer
