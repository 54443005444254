.error
	&__image
		max-width: 270px
		margin: 0 auto 42px auto
	&__svg
		display: block
		width: 100%
		height: 100%
	&__info
		text-align: center
	&__title
		margin-bottom: 20px
		+typography(title, small)
	&__subtitle
		max-width: 300px
		margin: 0 auto 24px auto
		+typography(text, medium)
		color: $east-bay
	&__button
		&._large
			padding: 18px 25px
	+min-screen($breakpoint-xs)
		display: flex
		flex-direction: column
		justify-content: center
		min-height: calc(100vh - 226px)
		&__image
			max-width: 354px
		&__title
			+typography(title, xlarge)
			margin-bottom: 40px
		&__subtitle
			max-width: 478px
			margin-bottom: 35px
			+typography(text, xlarge)
	+min-screen($breakpoint-sm)
		&__content
			display: flex
			align-items: center
		&__image
			width: 50%
			padding-right: 15px
			max-width: initial
			margin: 0
		&__svg
			width: 83%
			max-width: 525px
			margin: auto
		&__info
			width: 50%
			padding-left: 15px
			text-align: left
		&__subtitle
			max-width: initial
			margin-left: 0
			margin-right: 0
