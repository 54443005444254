.author
    $parent-selector: &
    display: flex
    align-items: center

    &._review
        flex-direction: row-reverse

    &__link
        text-decoration: none

    &__circle.card__link
        position: initial
        width: 25px
        height: 25px
        margin-left: 10px

    &__avatar
        margin-right: 14px
        display: block
        +size(57px, 55px)
        flex-shrink: 0
        border-radius: 16px
        overflow: hidden

    &._review &__avatar
        margin-left: 20px
        margin-right: 0
        box-shadow: 10px 10px 40px rgba($east-bay, .4)

    &__img
        +size(100%)
        object-fit: cover

    &._review &__inner
        display: flex
        flex-direction: column-reverse
        text-align: right

    &__company
        +typography(text, xxsmall)
        color: $light-gray


    &__statistic
        +typography(text, xxsmall)
        color: $east-bay

        &-item:last-child
            margin-left: 5px

    &._light &__company
        color: rgba(#fff, .5)

    &._review &__company
        +typography(text, medium)
        text-align: left

    &__name
        +typography(text, medium)
        color: $east-bay
        text-decoration: none

    a.author__name:hover
        color: $deep-cove

    &._light &__name
        color: $white
    &._light &__statistic
        color: $white

    &._review &__name
        +typography(text, xlarge)

    &._personal
        #{$parent-selector}
            &__name
                margin-bottom: 8px
                +typography(title, small)
                color: $deep-cove

            &__company
                font-weight: 600
                color: $deep-cove
                opacity: 0.5

            &__avatar
                margin-right: 12px
                +size(70px, 70px)
                border-radius: 20px

            &__inner
                display: flex
                flex-direction: column
                padding-top: 2px
                margin-bottom: -2px

    +min-screen($breakpoint-xs)
        &._detail
            #{$parent-selector}
                &__name
                    margin-bottom: 8px
                    +typography(title, large)
                    color: $deep-cove

                &__company
                    font-weight: 600
                    color: $deep-cove
                    opacity: 0.5

                &__avatar
                    margin-right: 30px
                    +size(100px, 100px)
                    border-radius: 20px

                &__inner
                    display: flex
                    flex-direction: column-reverse
        &._personal
            #{$parent-selector}
                &__name
                    +typography(title, large)

                &__avatar
                    margin-right: 30px
                    +size(100px, 100px)
