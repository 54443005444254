.navbar
    display: flex
    align-items: center
    justify-content: flex-end

    &._opened::before
        +max-screen($breakpoint-md - 1)
            position: fixed
            z-index: 5
            top: 0
            left: 0
            +size(100%)
            content: ''
            background-color: rgba($deep-cove, .5)

    &__inner
        display: flex
        align-items: center
        +max-screen($breakpoint-xs - 1)
            position: fixed
            z-index: 20
            top: 0
            left: 0
            padding: 50px
            +size(100%)
            text-align: center
            flex-direction: column
            align-items: center
            background-color: $white
            overflow-y: auto
            opacity: 0
            visibility: hidden
            display: none

    &._opened &__inner
        opacity: 1
        visibility: visible
        display: flex

    &__main-nav
        +between-screen($breakpoint-xs, $breakpoint-lg - 1)
            position: fixed
            z-index: 20
            top: 0
            right: 0
            bottom: 0
            padding: 50px 50px 50px 30px
            width: 280px
            opacity: 0
            visibility: hidden
            pointer-events: none
            background-color: #fff
            transform: translateX(100%)
            transition: all .2s $default-transition-function

        .main-nav__list
            +max-screen($breakpoint-lg - 1)
                flex-direction: column

        .main-nav__item
            &:not(:last-child)
                +max-screen($breakpoint-lg - 1)
                    margin: 0 0 50px

    &._opened &__main-nav
        +max-screen($breakpoint-lg - 1)
            pointer-events: inherit
            opacity: 1
            visibility: visible
            transform: translateX(0)
            transition: all .2s $default-transition-function

    &__burger
        margin-left: 30px
        +min-screen($breakpoint-lg)
            display: none

    &._opened &__burger
        z-index: 100
        color: $light-gray

    &__open-bid-button
        padding: 0 0 0 44px
        margin: 0 12px 0 6px

        +max-screen($breakpoint-xs)
            padding: 60px 0 0
            margin-bottom: 10px

    &__popover
        position: relative

    &__submenu
        position: absolute
        top: 35px
        padding-top: 7px
        visibility: hidden
        +max-screen($breakpoint-xs - 1)
            visibility: visible
            position: initial

    &__submenu-visible
        visibility: visible

    &__submenu-list
        min-width: 155px
        background-color: #FFFFFF
        border-radius: $border-radius
        padding: 10px 5px
        +max-screen($breakpoint-xs - 1)
            background-color: transparent
            border-radius: 0

    &__submenu-item
        +typography(text, medium)
        color: $east-bay
        text-decoration: none
        text-align: center
        cursor: pointer
