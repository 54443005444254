.registration
	&__title
		margin-bottom: 24px
	&__subtitle
		margin-bottom: 24px
		+typography(text, small)
	&__text
		+typography(caption, medium)
		color: $light-gray
		margin-top: 32px
		a
			color: inherit
		p
			margin-bottom: 15px
			&:last-child
				margin-bottom: 0
	&__progress
		margin: 80px -20px 0
	&__progress-text
		padding: 0 20px
		margin-bottom: 12px
		+typography(text, xsmall)
		font-weight: 900
		text-transform: uppercase
	&__progress-line
		display: flex
		width: 100%
		height: 5px
		background-color: $gray
	&__progress-inner
		width: 16.666666%
		height: 5px
		background-color: $green
	&__form
		max-width: 545px
	+min-screen($breakpoint-xs)
		&__subtitle
			margin-bottom: 30px
			+typography(text, xlarge)
		&__form-button
			margin-top: 16px
		&__content
			position: relative
			min-height: calc(100vh - 226px)
			padding-bottom: 120px
		&__progress
			position: absolute
			right: 0
			left: 0
			bottom: 0
	+min-screen($breakpoint-sm)
		&__progress
			margin: 0 -40px
		&__progress-text
			padding: 0 40px
