.invest-profile

  &__title
    +typography(title, medium)
    color: $deep-cove
    margin-bottom: 60px

  &__step
    +typography(text, xlarge)
    margin-bottom: 20px
    font-weight: 700

  &__content
    +typography(title, small)

  &__content_title
    margin-bottom: 36px

  &__content_image
    width: 100%
    height: auto

  &__content_answers_item
    padding: 17px
    background: #FFFFFF
    border: 1px solid #EBE9EA
    box-sizing: border-box
    border-radius: 4px
    margin: 10px 0

    &._active
      border-left: 3px solid #7175D8

    &_top
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center

      &:hover
        cursor: pointer

    &_name
      +typography(text, medium)
      font-weight: normal
      color: #9598A7

      &._active
        font-weight: 700
        color: $deep-cove

  &__pdf_container
    margin: 0 auto
    padding: 20px 0
    border-radius: 5px
    background-color: $light-gray-bg-2

    canvas
      margin: 0 auto

  &__actions
    display: flex
    flex-direction: row
    justify-content: flex-end
    margin-top: 20px
    text-align: right
    +max-screen($breakpoint-xs)
      flex-direction: column

    button
      &:last-child
        margin-left: 10px
      +max-screen($breakpoint-sm)
        &:last-child
          margin-left: 0
          margin-top: 10px

  &__error
    +typography(text, xlarge)
    text-align: center
    margin-top: 10px
    color: $red
    font-weight: normal

.react-datepicker-wrapper
  width: 100%

.date-picker
  &__field
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-start
    font-size: 16px
    font-weight: normal
    background: #FFFFFF
    border: 1px solid #EBE9EA
    box-sizing: border-box
    border-radius: 4px
    padding: 17px 38px
    cursor: initial

  &__error
    +typography(text, large)
    margin-top: 5px
    color: $red
    font-weight: normal

  &__field_placeholder
    color: #9598A7
    font-size: 16px
    margin-right: 20px

    &::before
      content: ''
      position: absolute
      left: 10px
      top: 14px
      width: 20px
      height: 20px
      background: url('/images/date-picker/calendar.svg') no-repeat center / contain


.pdf-document
  height: 500px
  overflow-y: auto
  text-align: center


.pdf-viewer
  &__confirmation-form
    margin-top: 20px
    display: flex
    justify-content: flex-end
    align-items: flex-start
    +max-screen($breakpoint-sm)
      flex-direction: column
      align-items: initial

    &__label
      width: 100%

    .timer
      .button__text
        min-width: 50px

    .invest-profile__actions
      margin-top: 0



.view-profile
  &__title
    +typography(title, small)

  &__description
    margin: 30px 0
    +typography(text, large)
    font-weight: 400
    color: $light-gray

  &__retry
    margin-right: 20px
    +max-screen($breakpoint-xs)
      margin-right: 0
      margin-bottom: 10px

.invest-profile-final
  &__title
    +typography(title, small)
    color: $deep-cove

  &__description
    margin: 30px 0
    +typography(text, large)
    font-weight: 400
    color: $light-gray
