.burger
	position: relative
	padding: 0
	display: block
	+size(30px, 14px)
	color: #fff
	&::before,
	&::after
		position: absolute
		left: 0
		content: ''
		height: 2px
		background-color: currentColor
		transition: width $default-transition-duration $default-transition-function
	&::before
		top: 0
		width: 15px
	&::after
		bottom: 0
		width: 20px
	&:hover::before,
	&:hover::after
		width: 100%
		transition: width .2s

	&._active
		&::before,
		&::after
			left: 50%
			width: 16px
		&::before
			top: 50%
			width: 16px
			transform: translate(-50%, -50%) rotate(45deg)
		&::after
			bottom: 50%
			transform: translate(-50%, 50%) rotate(-45deg)

	&__text
		display: block
		+hide-text
		height: 2px
		background-color: currentColor

	&._active &__text
		opacity: 0
