$font-family: Gilroy, 'Helvetica Neue', Arial, sans-serif
$default-font-size: 16px

$deep-cove: #050c42
$violet: #7175d8
$light-violet: #dfe0f4
$blue: #45b1e0
$light-blue: #e2f6ff
$red: #ff6b69
$east-bay: #43497b
$green: #5ecba1
$light-green: #dff5ec
$dark: #323441
$gray: #ebe9ea
$light-gray: #9598a7
$light-gray-bg: #f6f6f5
$light-gray-bg-2: #fafbfb
$light-gray-bg-3: #f2f3fb
$white: #ffffff

$green2: #97db9a
$green3: #cae992
$yellow: #ead587
$orange: #f6a179
$red2: #ff6c6a
$red3: #FF1510

$shadow: 0 20px 90px rgba($east-bay, .2)

$border-radius: 4px

$default-transition-duration: 0.4s
$default-transition-function: ease-out

$breakpoint-xl: 1440px
$breakpoint-lg: 1280px
$breakpoint-md: 1025px
$breakpoint-sm: 769px
$breakpoint-xs: 576px

// NEW

$primaryDark: #1C1D20
$primaryBlue: #2299FF
$primaryGray: #848484

$backgroundDark: #151617
$backgroundGray: #555556
