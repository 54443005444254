.ticker-wrapper
	display: flex
	flex-direction: row
	align-items: flex-start
	position: relative
	min-height: 38px
	+max-screen($breakpoint-sm)
		display: block

	&__period
		position: absolute
		z-index: 2
		background-color: $backgroundDark
		padding: 20px 60px 20px 0
		+typography(text, small)
		color: $white
		height: (14px * 1.21429 * 1)
		+max-screen($breakpoint-sm)
			position: initial
			background-color: initial
			padding: 0
			margin-top: 10px
			margin-bottom: -10px
	&__values
		width: 100%
		padding-right: 22px
		+max-screen($breakpoint-sm)
			padding-right: 0

.tickers
	display: flex
	height: (14px * 1.21429 * 1)
	white-space: nowrap
	color: $white
	overflow: hidden

	[data-smarquee-scroll-wrapper],
	[data-smarquee-scroll-title]
		display: flex

	&__item
		position: relative
		display: flex
		align-items: center
		margin-right: 40px
		&::after
			position: absolute
			top: 50%
			right: -20px
			margin-top: -6px
			content: ''
			+size(1px, 12px)
			background-color: rgba($backgroundGray, .4)

	&__link
		margin-right: 14px
		text-decoration: none
		color: inherit
		+typography(text, medium)

	&__value
		margin-right: 10px
		+typography(text, small)

	&__cell
		position: relative
		padding-left: 10px
		+typography(text, medium)
		&::before
			position: absolute
			top: 50%
			left: 0
			content: ''
		&._up
			color: $green
			&::before
				margin-top: -6px
				+arr(8, 6, currentColor, t)
		&._down
			color: $red
			&::before
				margin-top: -2px
				+arr(8, 6, currentColor, b)
