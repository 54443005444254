.article
    &__subtitle
        margin-top: 10px
        margin-bottom: 27px
        padding-bottom: 15px
        border-bottom: 1px solid $gray
        +typography(title, xxsmall)
        color: $light-gray
        font-weight: normal

    &__author
        margin: 20px 0 10px

    &__date
        margin-bottom: 10px
        color: $light-gray

    &__categories
        display: flex
        flex-direction: column
        align-items: flex-end


.ya-share2
    text-align: right
