.footer-nav
	margin: 30px 20px
	::before
		display: block
		content: attr(title)
		font-weight: bold
		height: 0
		overflow: hidden
		visibility: hidden
	
	&__list
		display: flex
		flex-direction: column
	&__item
		white-space: nowrap
		margin: 0 0 15px 0
	&__link
		text-decoration: none
		padding-bottom: 2px
		font-weight: 500
		color: $primaryDark
		@extend .animated
		&:hover
			font-weight: 700
	&__item._active &__link
		border-bottom: 2px solid $primaryBlue
