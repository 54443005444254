.table
	$parent-selector: &
	display: block
	tr, td, th, tbody
		display: block
	thead
		display: none
	th, td
		padding: 8px 0
		+typography(text, medium)
		&._nowrap
			white-space: nowrap

	&__content
		a
			color: $deep-cove
			text-decoration: none
		&._nobr
			white-space: nowrap

	&__row
		display: flex
		justify-content: space-between
		align-items: baseline

	&__title, th
		color: $light-gray

	&._bordered
		tr
			padding: 8px 0
			border-bottom: 1px solid $gray

	&._striped
		tr
			margin: 0 -20px
			padding: 10px 20px

		tbody
			tr
				&:nth-child(odd)
					background-color: $light-gray-bg

	&._v2
		th
			+typography(text, xsmall)
		#{$parent-selector}
			&__title,
			&__content
				box-sizing: border-box
				width: 50%
				padding: 4px 10px
			&__title
				+typography(text, xsmall)
				color: $deep-cove

	+min-screen($breakpoint-xs)
		display: table
		width: 100%
		max-width: 100%
		border-collapse: collapse
		box-sizing: border-box

		tr
			display: table-row

		td, th
			display: table-cell

		tbody
			display: table-row-group

		thead
			display: table-header-group

		th, td
			padding: 16px 10px
			vertical-align: top

		&__title
			display: none

		&._bordered
			td
				border-top: 1px solid #dee2e6

			td, th
				&:first-child
					padding-left: 0

				&:last-child
					padding-right: 0

			tr
				padding: 0
				border-bottom: none
				&:first-child

					td
						border-top: none

		&._striped
			border-collapse: separate
			border-spacing: 0

			td, th
				padding-left: 7px
				padding-right: 7px

				&:first-child
					padding-left: 0

				&:last-child
					padding-right: 0

			tr
				border-radius: 4px

			tbody
				tr
					&:nth-child(odd)
						background-color: transparent

						td
							background-color: $light-gray-bg

							&:first-child
								border-radius: 4px 0 0 4px

							&:last-child
								border-radius: 0 4px 4px 0

		&._v2
			#{$parent-selector}
				&__title,
				&__content
					width: 100%
					padding: 0

		+min-screen($breakpoint-md - 1)
			&._striped
				td, th
					padding-left: 10px
					padding-right: 10px

					&:first-child
						padding-left: 10px

					&:last-child
						padding-right: 10px
			&._v2
				th
					+typography(text, medium)

		+min-screen($breakpoint-md)
			th, td
				padding: 16px 15px

			&._striped
				td, th
					padding-left: 16px
					padding-right: 16px

					&:first-child
						padding-left: 16px

					&:last-child
						padding-right: 16px

			&._v2
				td, th
					&:nth-child(1)
						width: 13%
					&:nth-child(2)
						width: 13%
					&:nth-child(3)
						width: 13%
					&:nth-child(4)
						width: 9%
					&:nth-child(5),
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8),
					&:nth-child(9)
						width: 14%
						text-align: right

		+min-screen($breakpoint-lg)
			&._striped
				td, th
					padding-left: 20px
					padding-right: 20px

					&:first-child
						padding-left: 20px

					&:last-child
						padding-right: 20px

		+max-screen($breakpoint-sm - 10)
			&._v2
				display: block

				tr, td, th, tbody
					display: block

				thead
					display: none

				th, td
					padding: 8px 0
					+typography(text, medium)

				th
					+typography(text, xsmall)

				#{$parent-selector}
					&__title,
					&__content
						width: 50%
						padding: 4px 10px

					&__title
						display: block

				&._striped
					tr
						margin: 0 -20px 0 -30px
						padding: 10px 20px

					tbody
						tr
							&:nth-child(odd)
								background-color: $light-gray-bg

.table-new
	$parent-selector: &
	display: table
	width: 100%
	max-width: 100%
	border-collapse: collapse
	box-sizing: border-box

	tr
		display: table-row

	td, th
		display: table-cell

	tbody
		display: table-row-group

	thead
		display: table-header-group

	th, td
		padding: 16px 10px
		vertical-align: top

	&__title
		display: none

	&_striped
		tr
			margin: 0 -20px
			padding: 10px 20px

		tbody
			tr
				&:nth-child(odd)
					background-color: $light-gray-bg

	&__row
		display: flex
		align-items: baseline

		&-center
			justify-content: center

		&-left
			justify-content: flex-start

	&__title, th
		color: $light-gray

	&__content
		a
			color: $deep-cove
			text-decoration: none

		&._nobr
			white-space: nowrap

	&__show
		margin-top: 10px
		text-align: center
		cursor: pointer
		+typography(text, large)
		font-weight: 600

	+max-screen($breakpoint-md)
		display: block

		&__title
			display: block

		&__row
			align-items: center
			justify-content: space-between

		tr, td, th, tbody
			display: block

		thead
			display: none
		th, td
			padding: 8px 0
			+typography(text, medium)

			&._nowrap
				white-space: nowrap
