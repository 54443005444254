.author-detail
    $border-color: #ebe9ea

    &__author
        margin-bottom: 20px

    &__hr
        margin: 30px 0
        border: none
        border-top: 1px solid $border-color

    +min-screen($breakpoint-xs)
        &__header
            display: flex
            align-items: center
            justify-content: space-between

        &__author
            padding-right: 30px
            margin-bottom: 0
