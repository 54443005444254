.tabs
    $parent-selector: &

    &-strategy
        margin-top: 20px

        #{$parent-selector}
            &__nav-wrapper
                margin-bottom: 0


    &__nav-wrapper
        margin-bottom: 15px

    &__nav
        display: flex
        max-width: 315px
        padding-bottom: 15px


    &__nav-item
        position: relative
        display: inline-block
        padding-bottom: 10px
        text-decoration: none
        color: $east-bay
        +typography(text, large)
        cursor: pointer

        &:not(:last-child)
            margin-right: 48px

        &:after
            content: ""
            position: absolute
            left: 0
            bottom: 0
            width: 100%
            height: 1px
            background-color: $green
            opacity: 0
            transition: opacity 0.4s

        &._active,
        ._desktop &:hover
            color: $green

        &._active
            pointer-events: none

            &:after
                opacity: 1
                transition: none

    &__tab
        position: absolute
        z-index: 0
        transform: translateY(30px)
        opacity: 0

        &._active
            position: relative
            transition: opacity .7s, transform .7s
            transform: translateY(0)
            opacity: 1

    &._account
        padding-top: 30px
        border-top: 1px solid $gray

        #{$parent-selector}
            &__nav
                max-width: 500px
                justify-content: initial

            &__nav-item
                margin-right: 24px

    +max-screen($breakpoint-xs - 1)
        &._account
            padding-top: 24px
