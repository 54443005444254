.radio
	$parent-selector: &
	+typography(text, medium)
	color: $deep-cove
	user-select: none

	&__control
		+hidden

	&__text
		position: relative
		padding-left: 35px
		display: block
		margin-bottom: 24px
		&::before
			position: absolute
			top: -2px
			left: 0
			content: ''
			+size(20px)
			border-radius: 100%
			background-color: $gray
		&::after
			position: absolute
			top: 3px
			left: 5px
			content: ''
			+size(10px)
			border-radius: 100%
			background-color: $green
			opacity: 0

	&__control:checked ~ &__text::after
		opacity: 1

	&__link
		text-decoration: none
		color: $green

	&._last
		+min-screen($breakpoint-xs)
			#{$parent-selector}
				&__text
					margin-bottom: 40px
