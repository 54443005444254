.select__control
  .react-select
    $parent-selector: &
    &__indicator-separator
      display: none
    &__control
      padding: 7px 7px 7px 10px
      cursor: pointer
      border: 1px solid $gray
      border-radius: $border-radius
      background-color: #fff
      box-shadow: none
      font-size: 14px
      font-weight: 600
      &--menu-is-open
        border-color: #7175d8
        &:hover
          border-color: #7175d8
        .react-select__indicator.react-select__dropdown-indicator
          transform: rotate(180deg)
          color: #000
    &__multi-value
      background: transparent
      &__label
        font-size: 14px
        font-weight: 600
    &__menu
      box-shadow: 0 20px 90px rgba(67, 73, 123, 0.2)
      &-list
        padding: 0
    &__option
      padding: 20px
      font-size: 14px
      font-weight: 600
      color: #9598a7
      &--is-focused
        background: transparent
        color: #000
