.noUi-target,
.noUi-target *
	-webkit-touch-callout: none
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
	-webkit-user-select: none
	-ms-touch-action: none
	touch-action: none
	-ms-user-select: none
	-moz-user-select: none
	user-select: none
	-moz-box-sizing: border-box
	box-sizing: border-box

.noUi-target
	position: relative
	direction: ltr
	background: $gray
	border-radius: $border-radius

.noUi-base,
.noUi-connects
	width: 100%
	height: 100%
	position: relative
	z-index: 1
.noUi-connects
	overflow: hidden
	z-index: 0
	border-radius: 3px
	._danger &
		background: linear-gradient(90deg, #5ECBA1 0%, #E2F08F 50.53%, #FF6B69 100%)
.noUi-connect,
.noUi-origin
	will-change: transform
	position: absolute
	z-index: 1
	top: 0
	left: 0
	height: 100%
	width: 100%
	-ms-transform-origin: 0 0
	-webkit-transform-origin: 0 0
	-webkit-transform-style: preserve-3d
	transform-origin: 0 0
	transform-style: flat
html
	&:not([dir="rtl"])
		.noUi-horizontal
			.noUi-origin
				left: auto
				right: 0
			.noUi-handle
				right: -12px
				left: auto
.noUi-vertical
	width: 18px
	.noUi-origin
		width: 0
	.noUi-handle
		width: 28px
		height: 34px
		left: -6px
		top: -17px
		&:after
			top: 17px
	.noUi-draggable
		cursor: ns-resize
	.noUi-tooltip
		-webkit-transform: translate(0, -50%)
		transform: translate(0, -50%)
		top: 50%
		right: 120%
.noUi-horizontal
	height: 7px
	.noUi-origin
		height: 0
	.noUi-handle
		+size(24px)
		left: -12px
		top: -8px
	.noUi-tooltip
		-webkit-transform: translate(-50%, 0)
		transform: translate(-50%, 0)
		left: 50%
		bottom: 120%

.noUi-handle
	-webkit-backface-visibility: hidden
	backface-visibility: hidden
	position: absolute
	border: 7px solid $green
	border-radius: 3px
	background: #fff
	cursor: default
	border-radius: 50%
	cursor: pointer
	&:after
		left: 17px
	._danger &
		border-color: $red

.noUi-touch-area
	height: 100%
	width: 100%
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin
	-webkit-transition: transform 0.3s
	transition: transform 0.3s
.noUi-state-drag
	*
		cursor: inherit !important

.noUi-connect
	background-color: $green
	._danger &
		background: transparent

.noUi-draggable
	cursor: ew-resize
.noUi-active
	box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
[disabled]
	.noUi-connect
		background: #B8B8B8
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle
	cursor: not-allowed
.noUi-pips,
.noUi-pips *
	-moz-box-sizing: border-box
	box-sizing: border-box
.noUi-pips
	position: absolute
	color: #999
.noUi-value
	position: absolute
	white-space: nowrap
	text-align: center
	+typography(text, xsmall)
	color: $light-gray
	&:last-child
		left: 0 !important
		right: 0
		text-align: right

._danger .noUi-value
	margin-left: -3px
	&:after
		content: ''
		position: absolute
		width: 12px
		height: 12px
		border-radius: 100%
		top: -22px
		right: -3px
		background: #5ecba1
		pointer-events: none
	&[data-value="1"]
		margin-left: 1px
		&:after
			right: auto
			left: -2px
	&[data-value="2"]
		&:after
			background: #97db9a
	&[data-value="3"]
		&:after
			background: #cae992
	&[data-value="4"]
		&:after
			background: #ead587
	&[data-value="5"]
		&:after
			background: #f6a179
	&[data-value="6"]
		&:after
			background: #ff6c6a

.noUi-value-sub
	color: #ccc
	font-size: 10px
.noUi-marker
	position: absolute
	background: #CCC
.noUi-marker-sub
	background: #AAA
.noUi-marker-large
	background: #AAA
.noUi-pips-horizontal
	height: 20px
	top: 20px
	left: 0
	width: 100%
.noUi-rtl
	.noUi-value-horizontal
		-webkit-transform: translate(50%, 50%)
		transform: translate(50%, 50%)
	.noUi-value-vertical
		-webkit-transform: translate(0, 50%)
		transform: translate(0, 50%)
.noUi-marker-horizontal
	&.noUi-marker
		margin-left: -1px
		width: 2px
		height: 5px
	&.noUi-marker-sub
		height: 10px
	&.noUi-marker-large
		height: 15px
.noUi-pips-vertical
	padding: 0 10px
	height: 100%
	top: 0
	left: 100%
.noUi-value-vertical
	-webkit-transform: translate(0, -50%)
	transform: translate(0, -50%)
	padding-left: 25px
.noUi-marker-vertical
	&.noUi-marker
		width: 5px
		height: 2px
		margin-top: -1px
	&.noUi-marker-sub
		width: 10px
	&.noUi-marker-large
		width: 15px
.noUi-tooltip
	display: block
	position: absolute
	border: 1px solid #D9D9D9
	border-radius: 3px
	background: #fff
	color: #000
	padding: 5px
	text-align: center
	white-space: nowrap


.noUi-marker
	display: none

// .noUi-base
// 	position: relative
// 	z-index: 1
// 	height: 7px
// 	border-radius: 13px
// 	background-color: $gray

// .noUi-handle
// 	position: absolute
// 	top: calc(50% - 12px)
// 	right: -12px
// 	+size(24px)
// 	background-color: #fff
// 	border-radius: 50%
// 	border: 7px solid $green

// .noUi-pips
// 	position: relative
// 	margin-top: 16px

// .noUi-value
// 	position: absolute
// 	+typography(text, xsmall)
// 	color: $light-gray

// .noUi-connects
// 	position: relative
// 	z-index: 0
// 	overflow: hidden
// 	+size(100%)

// .noUi-connect,
// .noUi-origin
// 	position: absolute
// 	z-index: 1
// 	top: 0
// 	left: 0
// 	+size(100%)
// 	transform-origin: 0 0
// 	transform-style: flat
// 	will-change: transform

// .noUi-connect
// 	background-color: $green
// 	border-radius: 13px

// .noUi-origin
// 	left: auto
// 	right: 0
// 	height: 100%
