// A mixin for writing @font-face rules in SASS.
// See: https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
//
// Usage:
// Create a font face rule. Embedded OpenType, WOFF2, WOFF, TrueType, and SVG files are automatically sourced.
//
// @include font-face("Samplino", "fonts/Samplino")
//
// Rendered as CSS:
// @font-face {
//     font-family: "Samplino";
//     src: url("fonts/Samplino.eot?") format("eot"),
//          url("fonts/Samplino.woff2") format("woff2"),
//          url("fonts/Samplino.woff") format("woff"),
//          url("fonts/Samplino.ttf") format("truetype"),
//          url("fonts/Samplino.ico#Samplino") format("ico");
// }
//
// Create a font face rule that applies to bold and italic text.
//
// @include font-face("Samplina Neue", "fonts/SamplinaNeue", bold, italic);
//
// @font-face {
//     font-family: "Samplina Neue";
//     font-style: italic;
//     font-weight: bold;
//     src: url("fonts/SamplinaNeue.eot?") format("eot"),
//          url("fonts/SamplinaNeue.woff2") format("woff2"),
//          url("fonts/SamplinaNeue.woff") format("woff"),
//          url("fonts/SamplinaNeue.ttf") format("truetype"),
//          url("fonts/SamplinaNeue.ico#Samplina_Neue") format("ico");
// }

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}
