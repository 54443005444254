.header-list
    margin-top: 30px
    margin-bottom: 50px
    width: 100%
    height: 200px
    background-color: $white
    box-shadow: 0 20px 90px rgba(67, 73, 123, 0.2)
    border-radius: 20px
    +max-screen($breakpoint-sm)
        height: 350px

    &__container
        display: flex
        flex-direction: row
        height: 100%
        overflow: hidden
        border-radius: 20px
        +max-screen($breakpoint-sm)
            flex-direction: column

    &__content
        display: block
        flex-direction: column
        order: 2
        padding: 22px 40px
        text-align: left
        +max-screen($breakpoint-md)
            padding: 15px 20px
        +max-screen($breakpoint-sm)
            height: 60%
            order: 1

        &-subtitle
            font-size: 18px
            line-height: 2
            +max-screen($breakpoint-sm)
                font-size: 15px
                line-height: 1.375

    &__image
        flex-grow: 1
        order: 1
        min-width: 350px
        height: 100%
        overflow: hidden
        +max-screen($breakpoint-md)
            min-width: 250px
        +max-screen($breakpoint-sm)
            height: 100px
            position: relative
            order: 2
            overflow: initial
            background-color: $violet
            margin-top: 25px

    &__icon
        overflow: hidden

        &-type
            top: 0
            +max-screen($breakpoint-md)
                top: 6px
                transform: scale(1.2)
            +max-screen($breakpoint-sm)
                display: none

            &-mobile
                display: none
                width: 65px
                height: 65px
                position: absolute
                top: auto
                bottom: 18px
                right: 60px
                z-index: 2
                transform: scale(1.6)
                +max-screen($breakpoint-sm)
                    display: initial

        &-circle
            position: absolute
            width: 70px
            top: -107px
            right: -3px
            display: none
            z-index: 1
            +max-screen($breakpoint-sm)
                display: initial

