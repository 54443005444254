.accordion
    $parent-selector: &
    padding: 12px
    border-radius: 12px
    margin-bottom: 12px
    overflow: hidden
    background-color: $white
    box-shadow: 0 20px 90px rgba($east-bay, .23)

    &__header
        display: flex
        align-items: center
        justify-content: space-between

    &__title
        margin-left: 10px
        +typography(text, large)

    &__toggle
        display: flex
        align-items: center
        justify-content: center
        +size(20px)
        border-radius: 100%
        fill: $white
        flex-shrink: 0
        background-color: $deep-cove
        transition: background $default-transition-duration $default-transition-function, fill $default-transition-duration $default-transition-function

        &:hover
            background-color: $green
            transition: .2s

        .card__icon
            transition: transform .2s

    &._expanded
        #{$parent-selector}
            &__detail
                transform: translateY(0)
                opacity: 1

            &__toggle
                .card__icon
                    transform: rotate(-180deg)
