.support-faq
    &__title
        padding-top: 66px
        margin: 0 0 23px
        +typography(title, large)
        color: $deep-cove


    &__faqs
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: flex-start
        +max-screen($breakpoint-xs)
            flex-direction: column


    &__sidebar
        min-width: 30%
        padding-top: 64px
        margin-right: 10%

        &_list
            list-style: none
            margin: 0
            padding: 0
            padding-right: 32px

            &_item
                margin-bottom: 27px
                +typography(text, large)
                font-weight: normal
                color: $east-bay

                a
                    text-decoration: none
                    color: $east-bay

                &:first-child
                    margin-top: 0

                &:hover
                    cursor: pointer


.header-search
    position: relative
    height: 236px

    &:before
        position: absolute
        z-index: -1
        top: 0
        left: 50%
        content: ""
        margin-left: -50vw
        width: 100vw
        height: 100%
        background-color: rgba(235, 233, 234, 0.7)

    &__images
        position: absolute
        overflow: hidden
        z-index: -1
        top: 0
        left: 50%
        content: ""
        margin-left: -50vw
        width: 100vw
        height: 100%

    &__images-wrapper
        position: relative
        width: 100%
        height: 100%

    &__image-left
        position: absolute
        right: 0
        z-index: -1

    &__image-right
        position: absolute
        right: 0
        bottom: 0
        z-index: -1


.header-no-margin
    .page-header
        margin-bottom: 0


.faq-general
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-top: 60px
    +max-screen($breakpoint-xs)
        flex-direction: column
        text-align: center

    &__item
        width: 33%
        max-width: 33%
        margin-bottom: 60px
        +max-screen($breakpoint-xs)
            width: 100%
            max-width: 100%
        +between-screen($breakpoint-xs, $breakpoint-md)
            width: 49%
            max-width: 49%

    &__item-icon
        display: block
        svg
            cursor: pointer


.faq-question-list

    &__title
        margin: 30px 0
        cursor: pointer
        text-decoration: none
        +typography(title, medium)
        color: $deep-cove

    &__questions
        margin-top: 10px

    &__question
        line-height: 35px
        word-break: break-word
        cursor: pointer
        text-decoration: none
        color: $deep-cove

    &__more
        +typography(text, xlarge)


.faq-question-group
    margin-top: 50px

.faq-question
    margin-top: 50px

    &__title
        margin: 30px 0
        +typography(title, medium)


    &__description
        line-height: 35px
        word-break: break-word

        ul, ol
            margin: 10px 5px

        ul > li
            list-style: disc
            margin-left: 20px

        ol > li
            list-style: decimal
            margin-left: 20px
