.account
    $parent-selector: &
    position: relative
    overflow: hidden
    padding: 30px 20px
    color: $deep-cove
    background-color: #fff
    box-shadow: 0 20px 90px rgba($east-bay, .2)
    border-radius: 20px
    margin: 20px -20px 0

    &__header
        display: flex
        justify-content: space-between
        margin-bottom: 12px

    &__number
        +typography(title, xxsmall)
        margin-bottom: 24px

        &._main
            margin-bottom: 12px

    &__toggle
        display: flex
        align-items: center
        justify-content: center
        +size(38px)
        border-radius: 100%
        fill: #fff
        flex-shrink: 0
        background-color: $deep-cove
        transition: background $default-transition-duration $default-transition-function, fill $default-transition-duration $default-transition-function

        &:hover
            background-color: $green
            transition: .2s

        .card__icon
            transition: transform .2s

    &__title
        margin-bottom: 12px
        +typography(title, xsmall)

        &._main
            margin-bottom: 5px
            order: 1

    &__info-inner
        display: flex
        align-items: center

    &__caption
        +typography(text, xsmall)

        &:not(:last-child)
            margin-bottom: 10px

        &._small
            max-width: 96px

        &._light
            color: $light-gray

    &__value
        padding: 0 13px
        margin-right: 14px
        +typography(title, small)
        line-height: 38px
        color: #fff
        background-color: $green
        border-radius: $border-radius

    &__button
        width: 100%

        &:not(:last-child)
            margin-bottom: 12px

    &__info-content
        margin-bottom: 12px
        order: 2

    &__buttons
        order: 3

    &__detail
        // display: none
        // NOTE: Оставляем visible, потому что есть внутренние элементы с прокруткой,
        // которые должны уходить в края. Например, таблицы.
        overflow: visible
        padding-top: 24px
        will-change: height, opacity, transform
        transform: translateY(30px)
        transition: opacity .7s, transform .7s
        opacity: 0
        margin-top: 30px
        border-top: 1px solid $gray

    &__info-item
        margin-bottom: 20px

        &:last-child
            margin-bottom: 0

    &__info-item-prefix
        +typography(text, xsmall)
        color: $light-gray
        line-height: 32px

    &__info-item-title
        +typography(text, xsmall)
        color: $light-gray
        margin-bottom: 8px

    &__info-item-text
        +typography(title, xxsmall)

    &__chart
        margin-top: -18px
        align-self: center

    &__chart-up
        margin-top: -33px
        padding-left: 15px

    &__main-info
        margin-bottom: 20px

    &__main-card
        width: 100%
        padding: 24px 20px
        background: $light-gray-bg-3
        border-radius: 15px
        text-align: center

    &__main-text
        margin-bottom: 16px
        +typography(text, xsmall)

    &__main-text-grey
        color: $light-gray

    &__block
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        color: #fff
        z-index: 1000
        font-size: 20px
        font-weight: 800
        background: rgba($light-gray, 0.5)

    &__iir
        display: flex
        align-items: center
        margin-top: 10px
        +typography(text, medium)

    &__iir-label
        margin-left: 5px

    &__iir-download
        display: flex
        align-items: center

        +min-screen($breakpoint-md)
            align-items: flex-start

        &_icon
            cursor: pointer
            margin-left: 5px
            text-decoration: underline

            +min-screen($breakpoint-md)
                margin-top: -4px

    &__main
        display: flex
        flex-direction: column
        padding-bottom: 20px

        #{$parent-selector}
            &__number
                margin-bottom: 36px

    &._expanded
        #{$parent-selector}
            &__detail
                transform: translateY(0)
                opacity: 1

            &__toggle
                .card__icon
                    transform: rotate(-180deg)

    ._desktop &[data-expandable]:not(._expanded)
        cursor: pointer

    +min-screen($breakpoint-xs)
        margin-top: 30px
        padding-top: 30px
        padding-bottom: 30px
        padding-left: 30px
        &__title
            +typography(title, small)

            &._main
                margin-bottom: 10px
        &__info
            display: flex
            justify-content: space-between
            align-items: flex-end
        &__info-numbers
            order: 1
        &__detail
            padding-top: 24px
        &__button
            &._v2
                max-width: 196px
        &__number
            margin-bottom: 34px
        &__content
            display: flex
            flex-wrap: wrap
            align-items: flex-start
            max-width: 656px
        &__info-item
            &._v1,
            &._v2
                width: 25%
                padding-right: 20px

            &._v3
                width: 48%

            &._v2
                text-align: right

        &__chart
            margin-top: -22px

        &__main
            flex-direction: row
            padding-top: 20px
            justify-content: space-between

            #{$parent-selector}
                &__info-content
                    order: 3
                    width: 100%
                    margin-bottom: 0
                    margin-top: 50px

                &__buttons
                    display: flex
                    justify-content: flex-end
                    order: 2
                    width: 58%

                &__button
                    margin-right: 20px
                    margin-bottom: 0
                    max-width: 196px

                    &:last-child
                        margin-right: 0

                &__main-info
                    padding: 10px 30px 10px 0
                    margin-bottom: 0

                &__main-content
                    display: flex
                    width: 50%
                    max-width: 365px

                &__content
                    max-width: initial
                    display: block

                &__main-card
                    display: flex
                    flex-direction: column
                    justify-content: center

                &__number
                    margin-bottom: 46px
    +min-screen($breakpoint-sm)
        margin-left: 0
        margin-right: 0
        padding: 30px
        &__info-numbers
            order: 2
        &__info
            flex-shrink: 0
        &__title
            max-width: 390px
            margin-bottom: 0
            margin-right: 20px
        &__detail
            padding-top: 30px
        &._main
            #{$parent-selector}
                &__content
                    display: flex
                    flex-wrap: nowrap

                &__info-item
                    min-width: 170px
                    padding-right: 20px
                    margin-bottom: 0
    +min-screen($breakpoint-md)
        &__content
            flex-wrap: nowrap
            max-width: initial
        &__info-item
            margin-bottom: 0

            &._v1
                //order: 1
                width: 36.7%
                padding-right: 20px

            &._v2
                width: 30.3%
            //order: 4
            &._v3
                width: 16.5%
                text-align: right
                padding-right: 20px
            //order: 3
            &._v4
                width: 16.5%
                padding-right: 20px
                text-align: right
    //order: 2

    +max-screen($breakpoint-xs)
        &__info-item-prefix
            text-align: left
