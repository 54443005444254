.content-block
	$parent-selector: &

	&__inner
		+max-screen($breakpoint-xs - 1)
			height: (22px * 1.38889 * 4)
			overflow: hidden

		&-opened
			height: 100%

	&__text
		@extend .strategy-detail__text

	&__toggle
		margin-top: 16px
		+typography(text, medium)
		color: $green
		background: none
		+min-screen($breakpoint-xs)
			display: none

	&._show-all
		#{$parent-selector}
			&__inner
				height: auto

			&__toggle
				display: none

	&._text-small
		#{$parent-selector}
			&__text
				+typography(text, small)

		#{$parent-selector}__text + #{$parent-selector}__text
			margin-top: 17px

	&._autofollowing
		#{$parent-selector}
			&__toggle
				margin-top: 8px
				+typography(text, xsmall)

	&._two-col
		+min-screen($breakpoint-md)
			column-count: 2
			column-gap: 32px
	+min-screen($breakpoint-xs)
		&._text-small
			#{$parent-selector}
				&__text
					+typography(text, xlarge)
					font-weight: 400

			#{$parent-selector}__text + #{$parent-selector}__text
				margin-top: 30px

		&._autofollowing
			padding: 0 20px

