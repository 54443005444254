.form
	&__group
		&:not(:last-child)
			margin-bottom: 20px
	&__button
		margin-top: 10px
	&__info
		margin-top: 12px
		margin-bottom: 20px
		+typography(text, small)
		color: $light-gray

		&-available
			text-decoration: underline
			cursor: pointer
