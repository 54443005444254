.checkbox
	+typography(text, medium)
	color: $deep-cove
	user-select: none

	&__control
		+hidden

	&__text
		position: relative
		padding-left: 35px
		display: block
		&::before
			position: absolute
			top: -2px
			left: 0
			content: ''
			+size(20px)
			background-color: $gray
			background-position: center
			background-repeat: no-repeat
			border-radius: 2px

	&__control:checked ~ &__text::before
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAzLjhMNS4xMjUgOCAxMiAxIiBzdHJva2U9IiMwNTBDNDIiLz48L3N2Zz4=)

	&__link
		text-decoration: none
		color: $green
