.news-item
    $parent-selector: &
    $breakpoint-md: 1180px
    margin-bottom: 15px

    &._wide
        display: flex
        flex-direction: row
        +max-screen($breakpoint-xs)
            flex-direction: column

    &._long
        display: flex
        flex-direction: column
        +max-screen($breakpoint-md)
            flex-direction: row
        +max-screen($breakpoint-xs)
            flex-direction: column

    &._right
        #{$parent-selector}
            &__content
                +min-screen($breakpoint-lg)
                    padding-left: 0

            &__img
                width: 100%

            &__labels
                +min-screen($breakpoint-md)
                    margin-top: 15px

                &-item
                    +max-screen($breakpoint-md)
                        margin-top: 5px


    &__wrapper
        text-decoration: none

    &__img
        width: 47%
        min-width: 47%
        max-height: 181px
        min-height: 181px
        border-radius: 10px
        box-shadow: 0 20px 90px rgba(#43497b, .2)
        background-size: cover
        +between-screen($breakpoint-sm, $breakpoint-md)
            width: 320px
            min-width: 320px
            max-width: 320px
        +between-screen($breakpoint-xs, $breakpoint-sm)
            width: 281px
            min-width: 281px
            max-width: 281px
        +max-screen($breakpoint-xs)
            width: 100%
            min-width: 100%


    &__content
        padding: 0 30px 25px


    &__link
        margin: 5px 0 14px
        display: block
        +typography(title, xsmall)
        color: $deep-cove

    &__anons
        margin: 10px 0
        +typography(text, small)
        color: $east-bay

    &__date
        +typography(text, xxsmall)
        color: $light-gray

    &__labels
        display: flex
        flex-direction: row
        justify-content: flex-end
        flex-wrap: wrap



