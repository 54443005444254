.news
    $breakpoint-md: 1170px

    &__row
        +min-screen($breakpoint-md)
            display: flex

    &__col
        &:first-child
            width: (100% / 3) * 2
            min-width: (100% / 3) * 2
            margin-right: 30px
            +max-screen($breakpoint-md)
                width: 100%
                min-width: 100%
