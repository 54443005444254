.main-slider
    $breakpoint-sm: 980px

    margin-bottom: 40px
    +min-screen($breakpoint-xs)
        margin-bottom: 90px

    &__container
        @extend %container

    &__swiper
        height: 383px
        background-color: $white
        box-shadow: 0 20px 90px rgba(67, 73, 123, .2)
        border-radius: 20px

        +max-screen($breakpoint-xs)
            margin: 0 -20px
        +min-screen($breakpoint-xs)
            height: 383px
        +min-screen($breakpoint-sm)
            height: 383px


.swiper-slide
    overflow: hidden
