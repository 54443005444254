.article-card
    $parent-selector: &
    display: flex
    flex-direction: column
    color: $deep-cove
    text-decoration: none
    +max-screen($breakpoint-md)
        flex-direction: row
    +max-screen($breakpoint-xs)
        flex-direction: column

    &__img
        width: 100%
        max-height: 181px
        min-height: 181px
        border-radius: 10px
        box-shadow: 0 20px 90px rgba(#43497b, .2)
        background-size: cover
        +between-screen($breakpoint-sm, $breakpoint-md)
            width: 354px
            min-width: 354px
            max-width: 354px
        +between-screen($breakpoint-xs, $breakpoint-sm)
            width: 281px
            min-width: 281px
            max-width: 281px
        +max-screen($breakpoint-xs)
            width: 100%
            min-width: 100%

    &__content
        +max-screen($breakpoint-md)
            margin-left: 30px
        +max-screen($breakpoint-xs)
            margin-left: 0

    &__title
        margin: 5px 0 14px
        +typography(title, xsmall)

    &__anons
        margin: 10px 0
        +typography(text, small)
        color: $east-bay

    &__date
        +typography(text, xxsmall)
        color: $light-gray

    &__labels
        display: flex
        flex-direction: row
        justify-content: flex-end
        flex-wrap: wrap
        margin-top: 10px
